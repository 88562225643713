<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import AkReservation from '@components/general/AkReservation';
import {Icon} from '@iconify/vue/dist/iconify';
import permissionsMixin from '@mixins/permissionsMixin';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';

export default {
  components: {AkPictureAccommodation, AkReservation},
  mixins: [permissionsMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    slots: {
      type: Array,
      required: true,
    },
    nbDays: {
      type: Number,
      required: true,
    },
  },
  emits: ['add-reservation', 'edit-reservation', 'delete-reservation', 'update:modelValue'],

  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    gridClass() {
      if (this.nbDays === 15) return 'grid-template-15';
      return 'grid-template-30';
    },
    reservationsSlots() {
      return this.currentValue.reservationList;
    },
  },

  methods: {
    addReservation(data) {
      this.$emit('add-reservation', data);
    },
    editReservation(data) {
      this.$emit('edit-reservation', data);
    },
    deleteReservation(data) {
      this.$emit('delete-reservation', data);
    },

    tooltip(event, data) {
      this.$emit('tooltip', event, data);
    },

    getStatus() {
      for (let r of this.currentValue.reservationList) if (this.isActiveToday(r)) return 'busy';
      return 'free';
    },

    isActive(data, resa) {
      return (
        resa.dateStart.startOf('date').isSameOrBefore(data.date) &&
        resa.dateEnd.startOf('date').isSameOrAfter(data.date)
      );
    },

    isActiveToday(resa) {
      return (
        resa.dateStart.startOf('date').isSameOrBefore(moment()) && resa.dateEnd.startOf('date').isSameOrAfter(moment())
      );
    },

    displaySidebar(slot) {
      let y = slot.date.year();
      let m = slot.date.month();
      let d = slot.date.date();
      this.addReservation([y, m, d]);
    },
  },
};
</script>

<template>
  <div class="flex border-b last:border-b-0 border-lightGray">
    <div class="w-1/5 border border-l-0 border-r-0 border-lightGray border-y-0">
      <div class="flex gap-4 items-center py-2">
        <span v-if="!this.isOwner()" :class="getStatus()" class="status w-[2px] h-4/5" />
        <div class="w-[48px]">
          <AkPictureAccommodation
            :id="currentValue.accommodation.id"
            :height="48"
            :md5="currentValue.accommodation.pictureMd5"
            :radius="8" />
        </div>

        <div class="overflow-hidden flex-1 text-overflow-ellipsis flex flex-col gap-1">
          <router-link
            class="text-primary font-semibold text-base hover:text-primary"
            :to="{name: 'accommodationPage', params: {accommodationId: currentValue.accommodation.id}}">
            {{ currentValue.accommodation.name }}
          </router-link>
          <div class="flex gap-2 items-center">
            <router-link
              v-if="!this.isOwner()"
              class="text-gray font-medium text-sm hover:text-primary"
              :to="{name: 'ownerPage', params: {ownerId: currentValue.accommodation.ownerId}}">
              <span class="text-gray font-medium text-base">{{ currentValue.ownerLastName }}</span>
            </router-link>
            <div class="flex gap-2" v-if="currentValue.physical || currentValue.laundry">
              <i class="ga-icon ga-hand_key text-xl text-dark leading-[16px]" v-if="currentValue.physical"></i>
              <i class="ga-icon ga-shirt_cleaning text-xl text-dark leading-[16px]" v-if="currentValue.laundry"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-4/5 relative">
      <div class="grid w-full h-full gap-1.5 items-center grid-flow-col absolute" :class="this.gridClass">
        <AkReservation
          :slots="slots"
          :accommodation="currentValue.accommodation"
          :reservations="this.reservationsSlots"
          @edit-reservation="editReservation"
          @delete-reservation="deleteReservation"
          @tooltip="tooltip" />
      </div>
      <div class="grid w-full h-full items-center bg-detailsBg absolute" :class="this.gridClass">
        <div
          :key="slot"
          v-for="slot in slots"
          @click="displaySidebar(slot)"
          class="text-center text-slate-400 text-md col-span-2 h-full flex items-center justify-center"
          :class="{'bg-lightGray': slot.wk, 'bg-secondaryBg': slot.today}">
          <div>
            {{ slot.dayNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
