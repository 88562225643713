<script>
import {Icon} from '@iconify/vue';
import sidebarMixin from '../../../mixins/sidebarMixin';

export default {
  components: {Icon},
  mixins: [sidebarMixin],
  props: {
    md5: {
      type: String,
      required: false,
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    physical: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    laundry: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    radius: {
      type: [Number, String],
      required: false,
      default: 12,
    },
    rating: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabledLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    urlPicture() {
      if (this.md5) {
        return {
          background: 'url(' + import.meta.env.VUE_APP_PUBLIC_API_URL + '/picture/' + this.md5 + ')',
          'background-position': 'center',
          'background-size': 'cover',
          'border-radius': this.radius + 'px',
        };
      }
      return {background: 'url(' + require('@/assets/avatar.jpg') + ')', 'border-radius': this.radius + 'px'};
    },
    heightStyle() {
      if (this.cover) {
        return {width: '100%', height: this.height + 'px', 'border-radius': this.radius + 'px'};
      } else if (this.height) {
        return {height: this.height + 'px', 'border-radius': this.radius + 'px'};
      } else {
        return {height: '100%', 'border-radius': this.radius + 'px'};
      }
    },
  },
};
</script>

<template>
  <!-- Composant dynamique pour désactiver la redirection si la propriété disabledLink est à true -->
  <component
    :is="disabledLink ? 'div' : 'router-link'"
    @click="this.hideSidebar()"
    :to="{name: 'accommodationPage', params: {accommodationId: id}}"
    class="w-full flex justify-center">
    <div class="picture-accommodation relative" v-bind:style="heightStyle">
      <img
        class="pictures-acc"
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA"
        v-bind:style="urlPicture" />
      <div v-if="name" class="flex gap-2 items-center absolute bottom-1.5 left-1.5 rounded-lg bg-white p-2">
        <i class="ga-icon ga-hand_key text-dark text-base" v-if="physical"></i>
        <i class="ga-icon ga-shirt_cleaning text-dark text-base" v-if="laundry"></i>
        <span class="font-inter text-dark font-medium text-sm">{{ name }}</span>
      </div>

      <router-link
        v-if="rating"
        :to="{
          name: 'accommodationPage',
          params: {accommodationId: id},
          hash: '#accommodationReviews',
        }">
        <div class="flex flex-col gap-1 items-center absolute top-1.5 right-1.5 rounded-lg bg-white p-2">
          <span class="font-inter text-dark font-medium text-lg flex gap-1 items-center"
            ><Icon icon="ic:round-star" width="22px" color="#FFD600" />{{ rating.rating }}</span
          >
          <span class="font-inter text-grey font-medium text-xs">{{ rating.nbReviews }} avis</span>
        </div>
      </router-link>
    </div>
  </component>
</template>
