<script>
import {debounce} from '@utils';

/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';

export default {
  components: {AkInputText},
  mixins: [messageMixin],
  props: {
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: ['search'],
  data() {
    return {
      search: undefined,
      debounceFn: undefined,
    };
  },
  mounted() {
    this.debounceFn = debounce(() => this.$emit('search', this.search), 500);
  },
  computed: {
    hasFilterSlot() {
      return !!this.$slots.filter || !!this.$slots.action;
    },
    placeholder() {
      return this.searchPlaceholder ? this.searchPlaceholder : this.$t('search.search');
    },
    hasTabs() {
      return !!this.$slots.tabs;
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4 bg-white">
    <div
      class="flex justify-between items-center flex-col md:flex-row gap-2"
      v-if="hasFilterSlot || searchable || hasTabs">
      <perfect-scrollbar class="w-full md:w-auto" v-if="hasTabs">
        <slot name="tabs"></slot>
      </perfect-scrollbar>
      <div class="flex-1" v-if="hasTabs"></div>
      <div class="w-full md:w-auto">
        <AkInputText
          v-if="searchable"
          v-model="search"
          :display-label="false"
          icon="ga-icon ga-search"
          :placeholder="placeholder"
          inputClass="xs"
          @keyup="debounceFn"></AkInputText>
      </div>
      <div class="flex gap-2 items-center justify-end w-full md:w-auto flex-wrap">
        <slot name="filter"></slot>
        <div class="flex h-full items-start">
          <slot name="action"></slot>
        </div>
      </div>
    </div>
    <div>
      <slot name="content"></slot>
    </div>
  </div>
  <slot name="extra"></slot>
</template>
