<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkSidebar from '@components/v2/general/AkSidebar.vue';
import GaMessages from '@components/ga/layout/GaMessages.vue';
import AkInputTextArea from '@components/v2/input/AkInputTextArea';
import AkButtonFooterSidebar from '@components/v2/general/AkButtonFooterSidebar.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    AkSidebar,
    GaMessages,
    AkInputTextArea,
    AkButtonFooterSidebar,
  },
  mixins: [utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      sidebarVisible: false,
      litigeUpdate: {},
      submitted: false,
    };
  },
  validations() {
    return {
      litigeUpdate: {
        refusalComment: {required},
      },
    };
  },
  emits: ['litige-updated'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    // SIDEBAR

    show(litige) {
      this.sidebarVisible = true;
      this.initLitige(litige);
    },
    initLitige(litige) {
      if (!litige) return;
      this.litigeUpdate = {...litige};
    },
    hide() {
      this.submitted = false;
      this.litigeUpdate = undefined;
      this.sidebarVisible = false;
    },

    // PROCESS

    process() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.litigeUpdate.$error) {
        this.$refs.footerSidebar.init();
        return;
      }

      const litigeValidation = {
        id: this.litigeUpdate.id,
        validated: false,
        refusalComment: this.litigeUpdate.refusalComment,
      };

      litigeService
        .stepValidate(litigeValidation)
        .then((data) => {
          this.addSuccess(this.$t('litige.complete_refused'));
          this.$emit('litige-updated');
          this.hide();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.footerSidebar.init();
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <AkSidebar v-model:visible="sidebarVisible" :baseZIndex="9998" dismissable @hide="hide" block-scroll position="right">
    <template #container>
      <div class="mb-4">
        <GaMessages ref="sidebarMessages" />
      </div>
      <div class="p-sidebar-header">
        <div class="p-sidebar-header-content">{{ $t('litige.refuse_title') }}</div>
        <button class="p-sidebar-close p-sidebar-icon p-link" type="button" @click="hide">
          <span
            class="p-sidebar-close-icon ga-icon ga-close_circle text-4xl text-gray hover:text-primary hover:bg-transparent" />
        </button>
      </div>
      <div class="p-sidebar-content">
        <perfect-scrollbar class="h-full flex flex-col gap-4" :options="{suppressScrollX: true}">
          <div class="flex flex-col gap-2">
            <AkInputTextArea
              v-model="litigeUpdate.refusalComment"
              :label="$t('litige.reason')"
              :placeholder="$t('litige.reason')"
              :validator="v$.litigeUpdate.refusalComment"
              :submitted="submitted" />
          </div>

          <AkButtonFooterSidebar
            ref="footerSidebar"
            :cancelLabel="$t('cancel')"
            :validateLabel="$t('validate')"
            @action="process"
            @cancel="hide()" />
        </perfect-scrollbar>
      </div>
    </template>
  </AkSidebar>
</template>
