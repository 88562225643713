<script>
/* COMPONENTS */
import GaTripleView from '@components/v2/layout/GaTripleView.vue';
import AkAvatar from '@components/general/AkAvatar.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';
import CommercialProspects from '@views/commercials/CommercialProspects.vue';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import countryConst from '@mixins/const/countryConst';
import ownerTypesConst from '@mixins/const/ownerTypesConst';

/* SERVICES */
import commercialService from '@services/commercialService';
import itemLabelService from '@services/itemLabelService';
import GaTabView from '@components/v2/layout/GaTabView.vue';
import ProspectDetails from '@views/prospect/tabs/ProspectDetails.vue';
import ProspectActivity from '@views/prospect/tabs/ProspectActivity.vue';
import CommercialCrm from '@views/commercials/CommercialCrm.vue';
import prospectTabMixin from '@mixins/tab/prospectTabMixin';
import commercialTabMixin from '@mixins/tab/commercialTabMixin';

export default {
  components: {
    CommercialCrm,
    GaTabView,
    AkButtonActionValidation,
    AkAvatar,
    GaTripleView,
    CommercialProspects,
  },
  mixins: [
    permissionsMixin,
    backMixin,
    messageMixin,
    civilitiesConst,
    commercialTabMixin,
    utilsMixin,
    loaderMixin,
    countryConst,
    ownerTypesConst,
  ],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      commercial: {
        companyId: null,
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      },
      listFormeJuridique: [],
    };
  },
  mounted() {
    this.initGoBack();
    this.refresh();
  },
  computed: {
    title() {
      return this.commercial.display ?? this.$t('commercial.details');
    },
  },
  methods: {
    refresh() {
      this.showTotalLoaderGuestadom();

      let p1 = commercialService.commercial(this.$route.params.commercialId);
      p1.then((data) => {
        this.commercial = data;
      });

      let p2 = itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'});
      p2.then((data) => {
        this.listFormeJuridique = data;
      });

      Promise.all([p1, p2]).finally(() => this.hideLoader());
    },

    // ACTIONS

    generateAccess() {
      commercialService
        .access(this.$route.params.commercialId)
        .then((data) => {
          this.commercial = data;
          this.success(this.$t('commercial.accessSent'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          if (this.$refs.btnAccess) this.$refs.btnAccess.init();
        });
    },
    enableCommercial() {
      commercialService
        .enable(this.$route.params.commercialId)
        .then((data) => {
          this.commercial = data;
          this.commercial.userEnabled = true;
          this.success(this.$t('commercial.enabled'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.btnEnable.init();
        });
    },
    disableCommercial() {
      commercialService
        .disable(this.$route.params.commercialId)
        .then((data) => {
          this.commercial = data;
          this.commercial.userEnabled = false;
          this.success(this.$t('commercial.disabled'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.$refs.btnDisable.init();
        });
    },
  },
};
</script>

<template>
  <GaTripleView>
    <template #action>
      <AkButtonActionValidation
        v-if="!commercial.userEnabled"
        ref="btnEnable"
        :label="$t('enable')"
        :messagePopup="$t('confirm_enable', [this.commercial.display])"
        :titlePopup="$t('confirm_dialog')"
        success
        @click="enableCommercial" />
      <AkButtonActionValidation
        v-if="commercial.userEnabled"
        ref="btnDisable"
        :label="$t('disable')"
        :messagePopup="$t('confirm_disable', [this.commercial.display])"
        :titlePopup="$t('confirm_dialog')"
        danger
        @click="disableCommercial" />

      <AkButtonActionValidation
        v-if="!commercial.userId && commercial.userEnabled"
        ref="btnAccess"
        :label="$t('commercial.createAccess')"
        :messagePopup="$t('commercial.create_access_confirmation')"
        :titlePopup="$t('confirm_dialog')"
        secondary
        @click="generateAccess" />
      <AkButtonActionValidation
        v-if="commercial.userId && commercial.userEnabled"
        ref="btnAccess"
        :label="$t('commercial.resendAccess')"
        :messagePopup="$t('commercial.resend_access_confirmation')"
        :titlePopup="$t('confirm_dialog')"
        secondary
        @click="generateAccess" />

      <router-link
        v-if="commercial.id"
        :to="{name: 'commercialUpdate', params: {commercialId: commercial.id}}"
        class="btn-primary-gradient xs">
        {{ $t('update') }}
      </router-link>
    </template>
    <template #left>
      <div class="flex flex-col gap-4 md:gap-2">
        <div class="w-[160px] self-center">
          <AkAvatar
            v-if="commercial.userId"
            ref="avatar"
            :first-name="commercial.contact.firstName"
            :last-name="commercial.contact.lastName"
            :user-id="commercial.userId" />
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'contact', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Contact</span>
        </div>
        <div ref="contact">
          <div class="grid grid-cols-2 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.formeJuridique') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                listFormeJuridique.find((f) => f.id === commercial.contact.formeJuridiqueId)?.label
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.raisonSociale') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.raisonSociale
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.companyName') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.companyName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Capital (€)</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.capital }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.villeRcs') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.villeRcs
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.siret') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.siret }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.civility') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayForCivilityConst(commercial.contact.civility)
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.functionManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.function
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.lastNameManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.lastName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.firstNameManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                commercial.contact.firstName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.email') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.email }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.phone }}</span>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'address', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Adresse</span>
        </div>
        <div ref="address">
          <div :class="commercial.contact.address ? 'grid-cols-2' : 'grid-cols-1'" class="grid mt-4 gap-4 md:gap-2">
            <template v-if="commercial.contact.address">
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.address1Manager') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  commercial.contact.address
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.complement') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  commercial.contact.complement
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.postalCode') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  commercial.contact.postalCode
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.city') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.city }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.state') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{ commercial.contact.state }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.country') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  this.displayForCountryConst(commercial.contact.country)
                }}</span>
              </div>
            </template>
            <div v-else class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.address1Manager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">Adresse inconnue</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <GaTabView :tabs="tabs">
        <template #crm>
          <CommercialCrm />
        </template>
        <template #prospect>
          <CommercialProspects />
        </template>
      </GaTabView>
    </template>
    <template #right></template>
  </GaTripleView>
</template>
