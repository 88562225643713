<script>
/* COMPOSANTS */

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import messageMixin from '@mixins/messageMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionSuiviService from '@services/missionSuiviService';
import GaListView from '../../../components/v2/layout/GaListView.vue';
import GaView from '../../../components/v2/layout/GaView.vue';
import MissionCard from './MissionCard.vue';
import AkListFilter from '../../../components/v2/filter/AkListFilter.vue';
import companyMixin from '../../../mixins/companyMixin';
import filterListMixin from '../../../mixins/filterListMixin';
import permissionsMixin from '../../../mixins/permissionsMixin';
import AkAllFilter from '../../../components/v2/filter/AkAllFilter.vue';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter.vue';
import loaderMixin from '../../../mixins/loaderMixin';
import AkAutocompleteFilter from '../../../components/v2/filter/AkAutocompleteFilter.vue';
import ownerAutocompleteMixin from '../../../mixins/autocomplete/ownerAutocompleteMixin';

export default {
  components: {
    AkAutocompleteFilter,
    AkAllFilter,
    AkCalendarPeriodFilter,
    AkListFilter,
    MissionCard,
    GaView,
    GaListView,
  },
  mixins: [
    messageMixin,
    ownerAutocompleteMixin,
    companyMixin,
    loaderMixin,
    permissionsMixin,
    filterListMixin,
    randomRef,
    sidebarMixin,
    utilsMixin,
    missionMixin,
  ],
  metaInfo() {
    return {
      title: this.$t('mission_monitoring.title'),
    };
  },
  data() {
    return {
      dtoList: [],
      filter: {
        period: [new Date(), new Date()],
      },
    };
  },
  computed: {
    toDoPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'TO_DO');
    },
    doingPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'DOING');
    },
    finishPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'FINISH');
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    // FILTER
    openFilterPanel() {
      this.toggleFilterTitle('GaMissionSuiviFilter', this.$t('filter_all'), this.filter, this.search, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilterTitle('GaMissionSuiviFilter', this.$t('filter_all'), this.filter, this.search, this.resetFilter);
    },
    search() {
      if (!this.filter.period || !this.filter.period[0]) {
        this.addError('La période est obligatoire');
        return;
      }
      this.$nextTick(() => {
        this.showTotalLoaderWithAfter('Récupération des missions');
      });
      missionSuiviService
        .suivi(this.filter)
        .then((data) => {
          this.dtoList = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.hideLoader();
          });
        });
    },
    prevDay() {
      this.search();
    },
    nextDay() {
      this.search();
    },
    cssForType(type) {
      if (this.isTypeCheckin(type)) return 'checkin';
      if (this.isTypeCheckout(type)) return 'checkout';
      if (this.isTypeCleaning(type)) return 'cleaning';

      // A REDEFINIR ...
      if (this.isTypeCleaningAfter(type)) return 'cleaning';
      if (this.isTypeCleaningBefore(type)) return 'cleaning';
      if (this.isTypeRunning(type)) return 'cleaning';
      if (this.isTypeQualityControl(type)) return 'cleaning';
      if (this.isTypeMaintenance(type)) return 'cleaning';
      return type;
    },
    iconForType(type) {
      if (this.isTypeCheckin(type)) return 'mdi:clock-plus-outline';
      if (this.isTypeCheckout(type)) return 'mdi:clock-minus-outline';
      if (this.isTypeCleaning(type)) return 'mdi:vacuum';

      // A REDEFINIR ...
      if (this.isTypeCleaningAfter(type)) return 'mdi:vacuum';
      if (this.isTypeCleaningBefore(type)) return 'mdi:vacuum';
      if (this.isTypeRunning(type)) return 'mdi:vacuum';
      if (this.isTypeQualityControl(type)) return 'mdi:vacuum';
      if (this.isTypeMaintenance(type)) return 'mdi:vacuum';
      return type;
    },
    displayParticipantNb(item) {
      if (this.isAssignedStaffIntern(item.prestation)) return this.displayInternNb(item);
      if (this.isAssignedStaffHousekeeper(item.prestation)) return this.displayHousekeeperNb(item);
      return this.displayPrestataireNb(item);
    },
    displayInternNb(item) {
      if (item.participants.length == 0) return 'Aucun salarié';
      if (item.participants.length == 1) return '1 salarié';
      return item.participants.length + ' salariés';
    },
    displayPrestataireNb(item) {
      if (item.participants.length == 0) return 'Aucun prestataire';
      if (item.participants.length == 1) return '1 prestataire';
      return item.participants.length + ' prestataires';
    },
    displayHousekeeperNb(item) {
      if (item.participants.length == 0) return 'Aucun concierge';
      if (item.participants.length == 1) return '1 concierge';
      return item.participants.length + ' concierges';
    },
  },
};
</script>
<template>
  <GaView>
    <template #content>
      <GaListView>
        <template #tabs>
          <div class="flex gap-2 items-center flex-wrap">
            <div class="flex gap-1 items-center">
              <div class="--cleaning prestation-bg rounded-full flex items-center overflow-hidden w-4 h-4"></div>
              <span class="text-dark text-base font-normal">Ménage</span>
            </div>
            <div class="flex gap-1 items-center">
              <div class="--check-in prestation-bg rounded-full flex items-center overflow-hidden w-4 h-4"></div>
              <span class="text-dark text-base font-normal">Check-in</span>
            </div>
            <div class="flex gap-1 items-center">
              <div class="--check-out prestation-bg rounded-full flex items-center overflow-hidden w-4 h-4"></div>
              <span class="text-dark text-base font-normal">Check-out</span>
            </div>
            <div class="flex gap-1 items-center">
              <div class="--running prestation-bg rounded-full flex items-center overflow-hidden w-4 h-4"></div>
              <span class="text-dark text-base font-normal">Running / Maintenance / Contrôle qualité</span>
            </div>
          </div>
        </template>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />

          <AkCalendarPeriodFilter
            @search="this.clickSearch"
            label="Période"
            v-model="this.filter.period"
            direction="right" />

          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <div class="flex md:flex-row flex-col w-full gap-3">
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    @click="this.toggleElement(this.$refs, 'todoList', $event)"
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden" />
                  <span class="text-dark font-semibold text-base uppercase">{{ $t('mission_monitoring.todo') }}</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.toDoPrestations.length }}</span>
              </div>
              <div ref="todoList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.toDoPrestations.length > 0">
                  <MissionCard v-for="item in toDoPrestations" :key="item.prestation.id" :mission="item" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    @click="this.toggleElement(this.$refs, 'doingList', $event)"
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden" />
                  <span class="text-dark font-semibold text-base uppercase">{{ $t('mission_monitoring.doing') }}</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.doingPrestations.length }}</span>
              </div>
              <div ref="doingList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.doingPrestations.length > 0">
                  <MissionCard v-for="item in doingPrestations" :key="item.prestation.id" :mission="item" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    @click="this.toggleElement(this.$refs, 'doneList', $event)"
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden" />
                  <span class="text-dark font-semibold text-base uppercase">{{ $t('mission_monitoring.done') }}</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ this.finishPrestations.length }}</span>
              </div>
              <div ref="doneList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.finishPrestations.length > 0">
                  <MissionCard v-for="item in finishPrestations" :key="item.prestation.id" :mission="item" />
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
