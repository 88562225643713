<script>
/* COMPOSANTS */
import AkMultiSelect from '@components/v2/input/AkMultiSelect.vue';
import prestationTypesConst from '@mixins/const/prestationTypesConst';

export default {
  components: {AkMultiSelect},
  mixins: [prestationTypesConst],
  props: {
    modelValue: {
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isConsultation: {
      type: Boolean,
      required: false,
      default: false,
    },
    receptionTypeCheckin: {
      type: String,
      required: false,
      default: '',
    },
    receptionTypeCheckout: {
      type: String,
      required: false,
      default: '',
    },
    partnerMissionTypes: {
      type: Array,
      required: false,
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    filterAvailableMissionTypes() {
      if (this.isConsultation)
        return this.displayPrestationTypeConstForPartner(this.receptionTypeCheckin, this.receptionTypeCheckout);
      if (this.partnerMissionTypes) {
        let availableMissionsTypes = this.displayPrestationTypeConstForPartnerFilterMissionTypes(
          this.partnerMissionTypes,
          this.receptionTypeCheckin,
          this.receptionTypeCheckout,
        );
        return availableMissionsTypes.length > 0 ? availableMissionsTypes : undefined;
      }
      return this.displayPrestationTypeConstForPartner(this.receptionTypeCheckin, this.receptionTypeCheckout);
    },
  },
};
</script>

<template>
  <AkMultiSelect
    v-if="currentValue[index] && this.filterAvailableMissionTypes"
    v-model="currentValue[index].prestationTypes"
    :label="$t('prestation.list')"
    :options="this.filterAvailableMissionTypes"
    option-label="label"
    option-value="value" />

  <template v-else>
    <span v-if="!this.isConsultation">Aucune mission n'est disponible pour ce logement</span>
    <span v-else>Aucune mission n'a été attribuée sur ce logement</span>
  </template>
</template>
