<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView';
import GaListView from '@components/v2/layout/GaListView';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import reviewMixin from '@mixins/reviewMixin';
import filterListMixin from '@mixins/filterListMixin';
import companyMixin from '@mixins/companyMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';

/* SERVICES */
import reviewService from '@services/reviewService';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  components: {
    AkButtonAction,
    AkAllFilter,
    AkAutocompleteFilter,
    AkDatatablePagination,
    AkListFilter,
    GaListView,
    GaView,
  },
  mixins: [
    utilsMixin,
    randomRef,
    sidebarMixin,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    reviewMixin,
    filterListMixin,
    ownerAutocompleteMixin,
    companyMixin,
    reservationSiteConst,
  ],
  setup() {
    return {};
  },
  metaInfo() {
    return {
      title: 'review.title',
    };
  },
  data() {
    return {
      loading: true,
      filter: {
        page: 1,
        limit: 50,
        sort: ['submitted_at:DESC'],
      },
      list: [],
      stats: [],
      nbItems: 0,
    };
  },
  mounted() {},
  computed: {
    isEmptyList() {
      return !this.list || this.list.length === 0;
    },
  },
  methods: {
    search() {
      this.loading = true;
      reviewService
        .reviewsPagination(this.filter)
        .then((data) => {
          console.log(data);
          this.list = data.data.list;
          this.stats = data.data.stats;
          this.nbItems = data.items;
        })
        .finally(() => (this.loading = false));
    },
    scaleRating(site) {
      if (!site) return '10';
      let siteLower = site.toLowerCase();

      if (siteLower.includes('vrbo')) return '10';
      if (siteLower.includes('airbnb')) return '5';
      if (siteLower.includes('booking')) return '10';
      if (siteLower.includes('homeaway')) return '10';
      if (siteLower.includes('guest-adom')) return '5';
      if (siteLower.includes('guestadom')) return '5';
      return '10';
    },
    rating(review) {
      if (!review.reservationSite) return review.ratingTen;
      let siteLower = review.reservationSite.toLowerCase();

      if (siteLower.includes('vrbo')) return review.ratingTen;
      if (siteLower.includes('airbnb')) return review.rating;
      if (siteLower.includes('booking')) return review.ratingTen;
      if (siteLower.includes('homeaway')) return review.ratingTen;
      if (siteLower.includes('guest-adom')) return review.rating;
      if (siteLower.includes('guestadom')) return review.rating;
      return review.ratingTen;
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaReviewListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['submitted_at:DESC'],
      };
      this.toggleFilterTitle(
        'GaReviewListFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },

    // SIDEBAR

    rowClick(event) {
      let data = event.data;
      this.toggleSidebar('GaReviewSidebar', this.$t('review.details'), data.id);
    },

    // EXPORT

    exportList() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      reviewService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <div class="flex justify-end gap-4">
        <AkButtonAction
          secondary
          :label="$t('payment.download')"
          :disabled="this.isEmptyList"
          little
          @click="exportList()" />
      </div>
    </template>

    <template #content>
      <GaListView>
        <template #tabs>
          <div class="grid grid-cols-2 md:flex gap-2">
            <div
              class="border border-lightGray rounded-lg py-2 px-2 group-hover:border-secondary min-w-[120px]"
              v-for="item of this.stats"
              :key="item.reservationSite">
              <div class="flex items-center gap-4">
                <span
                  class="logo-site"
                  :class="iconReservation(item.reservationSite)"
                  v-tooltip.bottom="item.reservationSite" />
                <div class="flex flex-col">
                  <span
                    class="font-inter text-gray font-medium text-medium"
                    :class="this.colorRatingWithRating(item.reservationSite, item.globalRating)"
                    >{{ this.formatAmount(item.globalRating) }} / {{ item.scale }}</span
                  >
                  <span class="font-inter text-gray font-medium text-sm">{{ item.nbTotalReviews }} avis</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('review.company_name')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>

        <template #content>
          <AkDatatablePagination
            :loading="loading"
            :value="list"
            lazy
            :nb-items="this.nbItems"
            :filter="this.filter"
            @row-click="rowClick($event)"
            @search="this.search">
            <template #empty>
              {{ $t('review.empty') }}
            </template>

            <Column
              :header="$t('reservation.reservation_site')"
              header-class="text-center"
              style="width: 50px; text-align: center">
              <template #body="slotProps">
                <span
                  class="logo-site"
                  :class="iconReservation(slotProps.data.reservationSite)"
                  v-tooltip.bottom="slotProps.data.reservationSite" />
              </template>
            </Column>

            <Column
              :header="$t('company.one')"
              v-if="this.isGas()"
              style="width: 200px; min-width: 200px"
              :sortable="true"
              sort-field="j_company_name">
              <template #body="slotProps">
                {{ slotProps.data.companyName }}
              </template>
            </Column>
            <Column :header="$t('owner.ownerDisplay')" style="min-width: 100px; width: 100px">
              <template #body="slotProps">
                <span v-if="this.isPartenaire()">
                  {{ slotProps.data.ownerDisplay }}
                </span>
                <router-link
                  v-else
                  class="whitespace-pre-wrap"
                  :to="{
                    name: 'ownerPage',
                    params: {
                      ownerId: slotProps.data.ownerId,
                    },
                  }">
                  {{ slotProps.data.ownerDisplay }}
                </router-link>
              </template>
            </Column>

            <Column
              :header="$t('accommodation.short')"
              style="min-width: 300px; width: 300px"
              :sortable="true"
              sort-field="j_accommodation_name">
              <template #body="slotProps">
                <router-link
                  :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                  {{ slotProps.data.accommodationName }}
                </router-link>
              </template>
            </Column>

            <Column
              :header="$t('reservation.one')"
              style="min-width: 300px; width: 300px"
              :sortable="true"
              sort-field="j_reservation_num">
              <template #body="slotProps">
                <router-link
                  :to="{
                    name: 'reservationPage',
                    params: {
                      reservationId: slotProps.data.reservationId,
                    },
                  }">
                  {{ slotProps.data.reservationNum }}
                </router-link>
              </template>
            </Column>

            <Column :header="$t('review.name')" style="min-width: 100px; width: 100px">
              <template #body="slotProps">
                <span class="whitespace-pre-wrap">
                  {{ slotProps.data.name }}
                </span>
              </template>
            </Column>

            <Column
              :header="$t('review.rating_ten')"
              :sortable="true"
              field="rating_ten"
              style="min-width: 100px; width: 100px">
              <template #body="slotProps">
                <span :class="this.colorRating(slotProps.data)">
                  {{ this.formatAmount(this.rating(slotProps.data)) }} /
                  {{ this.scaleRating(slotProps.data.reservationSite) }}
                </span>
              </template>
            </Column>
            <Column
              :header="$t('review.submitted_at')"
              style="width: 150px; min-width: 150px"
              :sortable="true"
              sort-field="submitted_at">
              <template #body="slotProps">
                {{ this.formatDateIfNeeded(slotProps.data.submittedAt) }}
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
