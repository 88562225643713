<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* COMPOSANTS */
import AkReservationLine from '@components/general/AkReservationLine';
import AkReservationTooltip from '@components/general/AkReservationTooltip';

/* MIXINS */
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation.vue';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */

export default {
  components: {
    AkReservationLine,
  },
  mixins: [blockadeReasonsConst, reservationTypesConst, permissionsMixin],
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    dateRef: {
      type: moment,
      required: false,
      default: undefined,
    },
    nbDaysDisplay: {
      required: false,
      default: 15,
    },
  },
  emits: [
    'hide-loader',
    'next',
    'prev',
    'update:modelValue',
    'needReload',
    'add-reservation',
    'edit-reservation',
    'delete-reservation',
  ],
  data() {
    return {
      datePlanning: moment(),
      width: 0,
      reservationTooltip: undefined,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    nbLogements() {
      return this.currentValue.length;
    },
    dateReference() {
      if (this.dateRef === undefined) return this.datePlanning;
      return this.dateRef;
    },
    nbDays() {
      return this.nbDaysDisplay;
    },
    gridClass() {
      if (this.nbDaysDisplay === 15) return 'grid-template-15';
      return 'grid-template-30';
    },
    slots() {
      let list = [];

      //N'afficher que deux semaines

      let start = -10;
      let end = 20;
      if (this.nbDaysDisplay === 15) {
        start = -5;
        end = 10;
      }
      for (let i = start; i < end; i++) {
        let date = moment(this.dateReference).add(i, 'd').startOf('date');
        let slot = {};
        slot.date = date;
        slot.firstDayOfMonth = date.date() === 1;
        slot.firstDay = i === start;
        slot.dayNumber = date.format('D');
        slot.dayNumberTitle = date.format('D/MM');
        slot.dayTitle = date.format('ddd');
        slot.wk = date.isoWeekday() === 7 || date.isoWeekday() === 6;
        slot.today = date.isSame(moment().startOf('date'));
        slot.filter = date.isSame(moment(this.dateReference).startOf('date'));
        list.push(slot);
      }
      return list;
    },
    periodStartDisplay() {
      if (this.slots) return moment(this.slots[0].date).format('ddd D MMM');
      return '';
    },
    periodEndDisplay() {
      if (this.slots) return moment(this.slots[this.slots.length - 1].date).format('ddd D MMM');
      return '';
    },
  },

  methods: {
    addReservation(data) {
      this.$emit('add-reservation', data);
    },
    editReservation(data) {
      this.$emit('edit-reservation', data);
    },
    deleteReservation(data) {
      this.$emit('delete-reservation', data);
    },

    displayTooltip(event, reservation) {
      this.$refs.tooltip.hide();
      let that = this;
      setTimeout(function () {
        that.reservationTooltip = reservation ? reservation : undefined;
        if (that.reservationTooltip) {
          let target = event.target;
          if (target !== undefined) that.$refs.tooltip.toggle(event, target);
        }
      }, 100);
    },
  },
};
</script>

<template>
  <div class="flex justify-center items-center gap-2 mb-2">
    <i
      class="ga-icon ga-chevron_left text-4xl text-gray cursor-pointer hover:text-dark"
      @click="this.$emit('prev')"></i>
    <span class="font-normal text-xl text-gray"
      >Du <span class="capitalize text-dark font-medium">{{ periodStartDisplay }}</span> au
      <span class="capitalize text-dark font-medium">{{ periodEndDisplay }}</span></span
    >
    <i
      class="ga-icon ga-chevron_right text-4xl text-gray cursor-pointer hover:text-dark"
      @click="this.$emit('next')"></i>
  </div>
  <perfect-scrollbar class="ak-planning">
    <div class="flex border-b border-dashed border-lightGray min-w-[1500px] lg:min-w-[auto]">
      <div class="w-1/5 border-0 border-lightGray border-y-0">
        <div
          class="flex gap-4 items-center py-2 px-3.5 h-full text-sm font-semibold opacity-80 border-0 border-r border-lightGray uppercase text-gray">
          <div class="flex gap-4 items-center py-2 font-semibold uppercase text-gray">Logement</div>
        </div>
      </div>
      <div class="w-4/5 relative">
        <div class="grid w-full h-full items-center z-10" :class="this.gridClass">
          <div
            v-for="slot in slots"
            :class="{
              'text-dark': slot.today,
              'text-gray': !slot.today,
              'bg-lightGray': slot.wk,
              'bg-secondaryBg': slot.today,
            }"
            class="text-center col-span-2 opacity-80 h-full flex flex-col items-center justify-center text-sm font-semibold gap-2 px-2 py-4"
            :key="slot">
            <span class="uppercase font-semibold">{{ slot.dayTitle }}</span>
            <span class="font-semibold">{{ slot.dayNumberTitle }}</span>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar
      class="min-w-[1500px] lg:min-w-[auto] ps_rail_index border-l border-r border-lightGray"
      :options="{suppressScrollX: true}">
      <div
        v-if="currentValue && currentValue.length > 0"
        class="body"
        :style="[this.$isMobile() ? 'max-height: calc(100vh - 490px)' : 'max-height: calc(100vh - 375px)']">
        <AkReservationLine
          v-for="(p, idx) in currentValue"
          :key="p"
          v-model="currentValue[idx]"
          :nb-days="nbDays"
          :slots="slots"
          @tooltip="displayTooltip"
          @add-reservation="addReservation"
          @edit-reservation="editReservation"
          @delete-reservation="deleteReservation" />
      </div>
      <div v-else class="flex flex-col gap-2 mt-8 mb-8 items-center justify-center">
        <div>
          <span class="ga-icon ga-calendar text-4xl" />
        </div>
        <div>Aucune réservation</div>
      </div>
    </perfect-scrollbar>
    <div
      class="flex justify-between items-center gap-4 border px-2 py-2 border-lightGray min-w-[1500px] lg:min-w-[auto]">
      <div class="flex gap-4">
        <div class="flex gap-2 items-center">
          <i class="ga-icon ga-hand_key text-dark text-2xl"></i
          ><span class="text-dark text-base font-normal">Arrivée physique</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="ga-icon ga-shirt_cleaning text-dark text-2xl"></i
          ><span class="text-dark text-base font-normal">Linge inclus</span>
        </div>
        <div class="flex gap-2 items-center" v-if="!this.isOwner()">
          <i class="w-[12px] h-[12px] rounded-full booking-booked"></i
          ><span class="text-dark text-base font-normal">Confirmée</span>
        </div>
        <div class="flex gap-2 items-center" v-if="this.isOwner()">
          <i class="w-[12px] h-[12px] rounded-full booking-booked"></i
          ><span class="text-dark text-base font-normal">Occupée</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full booking-clotured"></i
          ><span class="text-dark text-base font-normal">Clôturée</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full booking-blockade"></i
          ><span class="text-dark text-base font-normal">Indisponible</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full booking-owner"></i
          ><span class="text-dark text-base font-normal">Hôte</span>
        </div>
        <div class="flex gap-2 items-center">
          <i class="w-[12px] h-[12px] rounded-full booking-tentative"></i
          ><span class="text-dark text-base font-normal">Demande d'informations</span>
        </div>
      </div>

      <div class="text-dark text-base font-normal">{{ nbLogements }} logements</div>
    </div>
  </perfect-scrollbar>
</template>
