<script>
export default {
  components: {},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '',
    // all titles will be injected into this template
    titleTemplate: '%s | GAPP',
  },
};
</script>

<template>
  <metainfo>
    <template v-slot:title="{content}">
      {{ content ? $i18n.t(content) + ` | ` + $i18n.t('app_name') : $i18n.t('app_name') }}
    </template>
  </metainfo>
  <RouterView />
</template>

<!-- This should generally be the only global CSS in the app. -->
<style>
@import '~normalize.css/normalize.css';
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';
@import '~primeicons/primeicons.css';
@import '~vue-loaders/dist/vue-loaders.css';
</style>
