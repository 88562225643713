<script>
import {Icon} from '@iconify/vue';
import ficService from '../../../services/ficService';

export default {
  components: {},
  props: {
    fic: {
      type: Object,
      required: false,
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
    radius: {
      type: [Number, String],
      required: false,
      default: 12,
    },
  },
  methods: {
    downloadFile(isPreview) {
      ficService.download(this.fic, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
  },
  computed: {
    urlPicture() {
      if (this.fic) {
        return {
          background: 'url(' + import.meta.env.VUE_APP_PUBLIC_API_URL + '/picture/' + this.fic.md5 + ')',
          'background-position': 'center',
          'background-size': 'cover',
          'border-radius': this.radius + 'px',
        };
      }
      return {background: 'url(' + require('@/assets/avatar.jpg') + ')', 'border-radius': this.radius + 'px'};
    },
    heightStyle() {
      if (this.cover) {
        return {width: '100%', height: this.height + 'px', 'border-radius': this.radius + 'px'};
      } else if (this.height) {
        return {height: this.height + 'px', 'border-radius': this.radius + 'px'};
      } else {
        return {height: '100%', 'border-radius': this.radius + 'px'};
      }
    },
  },
};
</script>

<template>
  <div class="picture-accommodation relative cursor-pointer" v-bind:style="heightStyle" @click="downloadFile(true)">
    <img
      class="pictures-acc"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA"
      v-bind:style="urlPicture"
      :alt="this.fic.name" />
  </div>
</template>
