<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import GaListView from '@components/v2/layout/GaListView.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import prospectOfferConst from '@mixins/const/prospectOfferConst';
import companyMixin from '@mixins/companyMixin';
import filterListMixin from '@mixins/filterListMixin';
import ownerService from '@services/ownerService';
import CrmCard from '@views/crm/components/CrmCard.vue';
import loaderMixin from '@mixins/loaderMixin';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import accommodationService from '@services/accommodationService';

/* SERVICES */

export default {
  components: {
    AkListFilter,
    AkAllFilter,
    AkInputFilter,
    CrmCard,
    GaListView,
    GaView,
  },
  mixins: [
    ownerTypesConst,
    companyMixin,
    filterListMixin,
    loaderMixin,
    randomRef,
    utilsMixin,
    messageMixin,
    prospectOfferConst,
    permissionsMixin,
    sidebarMixin,
    filterListMixin,
  ],
  metaInfo() {
    return {
      title: 'crm.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      filter: {},
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    getNewOwner() {
      return this.list.filter((o) => o.statusProspectFilter === 'NOUVEAU');
    },
    getEnCoursOwner() {
      return this.list.filter((o) => o.statusProspectFilter === 'EN_COURS');
    },
    getTermineOwner() {
      return this.list.filter((o) => o.statusProspectFilter === 'GAGNE' || o.statusProspectFilter === 'PERDU');
    },
  },
  methods: {
    exportCrm() {
      this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
      ownerService.exporOwnerCrm(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilterTitle('GaCrmFilterList', this.$t('filter_all'), this.filter, this.clickSearch, this.resetFilter);
    },
    search() {
      this.$nextTick(() => {
        this.hideLoader();
        this.showTotalLoaderWithAfter('Récupération des clients en cours...');
      });
      ownerService
        .ownerCrm(this.filter)
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.hideLoader();
          });
        });
    },
    openFilterPanel() {
      this.toggleFilterTitle('GaCrmFilterList', this.$t('filter_all'), this.filter, this.clickSearch, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView>
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <button class="btn-light-gray xs" @click="exportCrm()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.search"
            :label="$t('employee.company')"
            direction="right" />
          <AkInputFilter @search="search" v-model="filter.ownerName" :label="$t('interlocutor.prospect')" />
          <AkInputFilter @search="search" v-model="filter.email" :label="$t('contact.email')" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <div class="flex md:flex-row flex-col w-full gap-3">
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min" v-if="!this.isOwner()">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'newList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Nouveau</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ getNewOwner.length }}</span>
              </div>
              <div ref="newList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.getNewOwner.length > 0">
                  <CrmCard :owner="item" v-for="item of this.getNewOwner" :key="item.dateCreated" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'progressList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">En cours</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ getEnCoursOwner.length }}</span>
              </div>
              <div ref="progressList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.getEnCoursOwner.length > 0">
                  <CrmCard :owner="item" v-for="item of this.getEnCoursOwner" :key="item.dateCreated" />
                </perfect-scrollbar>
              </div>
            </div>
            <div class="flex-1 bg-[#F0F1F4] bg-opacity-50 rounded-lg p-3 h-min">
              <div class="flex justify-between items-center px-2 py-4">
                <div class="flex gap-2 items-center">
                  <i
                    class="ga-icon ga-chevron_up text-2xl leading-[16px] cursor-pointer text-gray md:hidden"
                    @click="this.toggleElement(this.$refs, 'closedList', $event)" />
                  <span class="text-dark font-semibold text-base uppercase">Terminé</span>
                </div>
                <span class="text-dark font-semibold text-base uppercase">{{ getTermineOwner.length }}</span>
              </div>
              <div ref="closedList">
                <perfect-scrollbar
                  class="flex flex-col gap-2 mt-3 md:max-h-[calc(100vh_-_300px)] h-full"
                  v-if="this.getTermineOwner.length > 0">
                  <CrmCard :owner="item" v-for="item of this.getTermineOwner" :key="item.dateCreated" />
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </template>
      </GaListView>
    </template>
    <template #extra></template>
  </GaView>
</template>
