<script>
import AkLabel from '@components/v2/general/AkLabel';
import addressFormatter from '@mixins/addressFormatter';

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'short_name',
  postal_code: 'short_name',
};

const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
const REGIONS_TYPE = [
  'locality',
  'sublocality',
  'postal_code',
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
];

/*
  By default, we're only including basic place data because requesting these
  fields place data is not additionally charged by Google. Please refer to:

  https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
*/
const BASIC_DATA_FIELDS = [
  'address_components',
  'adr_address',
  'alt_id',
  'formatted_address',
  'geometry',
  'icon',
  'id',
  'name',
  'business_status',
  'photo',
  'place_id',
  'scope',
  'type',
  'url',
  'vicinity',
];

export default {
  name: 'VueGoogleAutocomplete',
  components: {AkLabel},
  mixins: [addressFormatter],
  inject: {
    pSubmitted: {default: null},
    pDisabled: {default: null},
    pIgnoredValidator: {default: null},
  },

  props: {
    mapApiLoaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    types: {
      type: String,
      default: 'address',
    },
    fields: {
      type: Array,
      default: function () {
        return BASIC_DATA_FIELDS;
      },
    },
    country: {
      type: [String, Array],
      default: null,
    },

    geolocationOptions: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    updatable: {
      type: Boolean,
      required: false,
      default: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    ignoredValidator: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  emits: [
    'update:modelValue',
    'inputChange',
    'no-results-found',
    'clear',
    'focus',
    'blur',
    'change',
    'keypress',
    'keyup',
    'place-changed',
    'error',
  ],

  data() {
    return {
      mapId: 'id' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,

      /**
       * Autocomplete input text
       * @type {String}
       */
      autocompleteText: '',

      geolocation: {
        /**
         * Google Geocoder Objet
         * @type {Geocoder}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,

        /**
         * Filled after geolocate result
         * @type {Coordinates}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,

        /**
         * Filled after geolocate result
         * @type {Position}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null,
      },
    };
  },
  computed: {
    getItem() {
      return this.modelValue;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    getIgnoredValidator() {
      if (this.ignoredValidator != undefined) return this.ignoredValidator;
      if (this.pIgnoredValidator != undefined) return this.pIgnoredValidator;
      return [];
    },
    isInvalid() {
      if (this.hasValidator && this.getIgnoredValidator.length > 0) {
        for (let error of this.validator.$errors) {
          if (this.getIgnoredValidator.indexOf(error.$validator) === -1) {
            return true;
          }
        }
      } else {
        return this.hasValidator && this.validator.$invalid;
      }
      return false;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params === undefined ||
          this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.ignoredValidator.indexOf('required') === -1 &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },

  watch: {
    autocompleteText: function (newVal, oldVal) {
      if (!newVal) this.clear();
      this.$emit('inputChange', {newVal, oldVal}, this.mapId);
    },
    modelValue: function (newVal) {
      // watch it
      this.autocompleteText = this.formatAddr(newVal);
    },
    mapApiLoaded: function (newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
      if (newVal === true) {
        this.loadAutocomplete();
      }
    },
    country: function () {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country,
      });
    },
  },

  mounted: function () {
    this.loadAutocomplete();
  },

  methods: {
    loadAutocomplete() {
      const options = {};
      if (this.types) {
        options.types = [this.types];
      }
      if (this.country) {
        options.componentRestrictions = {
          country: this.country,
        };
      }

      if (this.mapApiLoaded) {
        console.log('On charge');
        this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(this.mapId), options);

        this.autocomplete.setFields(this.fields);
        this.autocomplete.addListener('place_changed', this.onPlaceChanged);
        this.autocompleteText = this.formatAddrCountryCode(this.modelValue);
      }
    },
    /**
     * When a place changed
     */
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        this.$emit('no-results-found', place, this.mapId);
        this.clear();
        return;
      }

      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.fillAddressData(this.formatResult(place), place);
        this.autocompleteText = this.formatAddrCountryCode(this.getItem);
        this.onChange();
      }
    },
    fillAddressData: function (addressData, placeResultData) {
      this.getItem.address = (addressData.street_number ? addressData.street_number + ' ' : '') + addressData.route;
      this.getItem.postalCode = addressData.postal_code;
      this.getItem.city = addressData.locality;
      this.getItem.state = addressData.administrative_area_level_2;
      this.getItem.country = addressData.country;
      this.getItem.latitude = addressData.latitude;
      this.getItem.longitude = addressData.longitude;
      this.getItem.placeId = placeResultData.place_id;
      this.$emit('place-changed', placeResultData);
    },

    /**
     * When the input gets focus
     */
    onFocus() {
      this.$emit('focus');
    },

    /**
     * When the input loses focus
     */
    onBlur() {
      this.$emit('blur');
    },

    /**
     * When the input got changed
     */
    onChange() {
      this.$emit('change', this.autocompleteText);
    },

    /**
     * When a key gets pressed
     * @param  {Event} event A keypress event
     */
    onKeyPress(event) {
      this.$emit('keypress', event);
    },

    /**
     * When a keyup occurs
     * @param  {Event} event A keyup event
     */
    onKeyUp(event) {
      this.$emit('keyup', event);
    },

    /**
     * Clear the input
     */
    clear() {
      this.autocompleteText = '';
      this.getItem.address = undefined;
      this.getItem.postalCode = undefined;
      this.getItem.city = undefined;
      this.getItem.state = undefined;
      this.getItem.country = undefined;
      this.getItem.latitude = undefined;
      this.getItem.longitude = undefined;
      this.getItem.placeId = undefined;
      this.$emit('clear');
    },

    /**
     * Focus the input
     */
    focus() {
      this.$refs.autocomplete.focus();
    },

    /**
     * Blur the input
     */
    blur() {
      this.$refs.autocomplete.blur();
    },

    /**
     * Update the value of the input
     * @param  {String} value
     */
    update(value) {
      this.autocompleteText = value;
    },

    /**
     * Update the coordinates of the input
     * @param  {Coordinates} value
     */
    updateCoordinates(value) {
      if (!value && !(value.lat || value.lng)) return;
      if (!this.geolocation.geocoder) this.geolocation.geocoder = new window.google.maps.Geocoder();
      this.geolocation.geocoder.geocode({location: value}, (results, status) => {
        if (status === 'OK') {
          results = this.filterGeocodeResultTypes(results);
          if (results[0]) {
            this.$emit('place-changed', this.formatResult(results[0]), results[0], this.mapId);
            this.update(results[0].formatted_address);
          } else {
            this.$emit('error', 'no result for provided coordinates');
          }
        } else {
          this.$emit('error', 'error getting address from coords');
        }
      });
    },

    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {{formatted output}}
     */
    formatResult(place) {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData['latitude'] = place.geometry.location.lat();
      returnData['longitude'] = place.geometry.location.lng();
      return returnData;
    },

    /**
     * Extract configured types out of raw result as
     * Geocode API does not allow to do it
     * @param results
     * @returns {GeocoderResult}
     * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
     */
    filterGeocodeResultTypes(results) {
      if (!results || !this.types) return results;
      let output = [];
      let types = [this.types];
      if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
      if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

      for (let r of results) {
        for (let t of r.types) {
          if (types.includes(t)) {
            output.push(r);
            break;
          }
        }
      }
      return output;
    },
    openGoogleMap() {
      window.open('https://maps.google.com/?q=' + this.getItem.latitude + ',' + this.getItem.longitude, '_blank');
    },
  },
};
</script>
<template>
  <div :class="className" class="w-full input-layout form-control">
    <AkLabel :required="this.isRequired || this.required === true">
      {{ label }}
      <template v-if="this.getItem.latitude && this.getItem.longitude" #link>
        <i class="ga-icon ga-external text-primary text-2xl leading-4 cursor-pointer" @click="openGoogleMap" />
      </template>
    </AkLabel>
    <span class="relative">
      <input
        :id="mapId"
        ref="autocomplete"
        v-model="autocompleteText"
        :class="{'p-invalid': isInvalid && alreadySubmitted}"
        :disabled="isDisabled"
        :placeholder="getPlaceholder"
        class="w-full text-dark text-base font-inter form-control p-inputtext p-component pr-[34px!important]"
        type="text"
        @blur="onBlur()"
        @change="onChange"
        @focus="onFocus()"
        @keypress="onKeyPress"
        @keyup="onKeyUp" />
      <i
        v-if="autocompleteText && !isDisabled"
        class="ga-icon ga-close_circle text-gray text-2xl hover:text-primary cursor-pointer absolute right-[12px] top-[-4px]"
        @click="clear" />
    </span>

    <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>
  </div>
</template>
