<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import ownerTypesConst from '@mixins/const/ownerTypesConst';

/* SERVICES */
import companyMixin from '@mixins/companyMixin';
import commercialService from '@services/commercialService';
import prospectOfferConst from '@mixins/const/prospectOfferConst';

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkInputText,
    AkDropdown,
    MultiSelect,
  },
  mixins: [
    permissionsMixin,
    companyMixin,
    utilsMixin,
    sidebarMixin,
    accommodationTypeConst,
    residenceTypeConst,
    prospectOfferConst,
    ownerTypesConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      enabledStateConst: [
        {label: this.$t('all'), value: null},
        {label: this.$t('enabled_only'), value: true},
        {label: this.$t('disabled_only'), value: false},
      ],
      statusProspectConst: [
        {label: 'Nouveau', value: 'NOUVEAU'},
        {label: 'En cours', value: 'EN_COURS'},
        {label: 'Gagné', value: 'GAGNE'},
        {label: 'Perdu', value: 'PERDU'},
      ],
      loadingCommercial: true,
      commercials: [],
    };
  },
  mounted() {
    if (this.isGasGav())
      commercialService.commercials().then((data) => {
        this.commercials = data;
        this.commercials.push({id: undefined, display: '---'});
        this.loadingCommercial = false;
      });
  },
  methods: {
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
      this.itemSelect();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :loading="this.loadingCompanies"
      :placeholder="$t('employee.company')"
      class="form-control"
      option-label="name"
      filter
      filter-placeholder="Filtrer"
      @change="itemSelect"
      option-value="id" />
    <AkDropdown
      v-if="this.isGasGav()"
      @valueChange="itemSelect"
      v-model="currentValue.commercialId"
      inline
      :loading="this.loadingCommercial"
      :label="$t('onboarding.commercialId')"
      :options="this.commercials"
      option-label="display"
      option-value="id" />

    <AkInputText
      @valueChange="itemSelect"
      v-model="currentValue.ownerName"
      :placeholder="$t('interlocutor.prospect')"
      :inline="true" />
    <AkInputText
      @valueChange="itemSelect"
      v-model="currentValue.email"
      :placeholder="$t('contact.email')"
      :inline="true" />
    <AkInputText
      @valueChange="itemSelect"
      v-model="currentValue.phone"
      :placeholder="$t('contact.phone')"
      :inline="true" />
    <MultiSelect
      @change="itemSelect"
      v-model="currentValue.ownerTypes"
      inline
      :placeholder="$t('prospect.ownerType')"
      :options="ownerTypesConstFilter"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="value" />

    <AkDropdown
      @valueChange="itemSelect"
      v-model="currentValue.offer"
      inline
      :label="$t('prospect.offer')"
      :options="prospectOfferConst"
      option-label="label"
      option-value="value" />

    <AkDropdown
      @valueChange="itemSelect"
      v-model="currentValue.userEnabled"
      inline
      :label="$t('status')"
      :options="enabledStateConst"
      option-label="label"
      option-value="value" />

    <MultiSelect
      v-model="currentValue.statusProspect"
      :options="statusProspectConst"
      placeholder="Statut du prospect"
      class="form-control"
      option-label="label"
      @change="itemSelect"
      option-value="value" />

    <AkButtonFooterSidebarFilter @reset="this.reset" @action="search" validate-label="Rechercher" />
  </form>
</template>
