<script>
import moment from 'moment/moment';
import {getDayShort, getMonthShort} from '@utils/dateTime';

/* COMPOSANTS */
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';
import AkListFilter from '@components/v2/filter/AkListFilter';
import GaListView from '@components/v2/layout/GaListView';
import GaView from '@components/v2/layout/GaView';
import AccommodationPlanningList from './mission/AccommodationPlanningList';

/* MIXINS */
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import companyMixin from '@mixins/companyMixin';
import filterListMixin from '@mixins/filterListMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import missionMixin from '@mixins/missionMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import prestationStatusFilterConst from '@mixins/const/prestationStatusConst';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import accommodationService from '@/services/accommodationService';

export default {
  components: {
    AccommodationPlanningList,
    AkAllFilter,
    AkAutocompleteFilter,
    AkListFilter,
    GaView,
    GaListView,
  },
  mixins: [
    prestationTypesConst,
    prestationStatusFilterConst,
    personnelTypeConst,
    permissionsMixin,
    utilsMixin,
    missionMixin,
    messageMixin,
    filterListMixin,
    companyMixin,
    sidebarMixin,
    ownerAutocompleteMixin,
    loaderMixin,
    accommodationAutocompleteMixin,
    accommodationService,
  ],
  data() {
    return {
      loading: false,
      planninglist: [],
      dates: undefined,
      filter: {},
    };
  },
  metaInfo() {
    return {
      title: 'planning_accommodation.title',
    };
  },
  computed: {
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -2);
    },
    prestationStatusList() {
      return this.prestationStatusFilterConst.slice(0, -1);
    },
    periodStartDisplay() {
      if (this.dates) return moment(this.dates[0]).format('ddd D MMM');
      return '';
    },
    periodEndDisplay() {
      if (this.dates) return moment(this.dates[this.dates.length - 1]).format('ddd D MMM');
      return '';
    },
  },
  mounted() {
    this.getCurrentWeek();
  },
  watch: {},
  methods: {
    search() {
      // Reset les listes pour forcer le rafraîchissement des Datatable
      this.$nextTick(() => {
        this.showLoader(this.$refs.planning);
      });

      accommodationService
        .planning(this.filter)
        .then((data) => {
          this.planninglist = data;
          this.dates = this.getDatesStrOfTheWeek(this.filter.dateStart);
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.hideLoader();
          });
        });
    },

    getCurrentWeek() {
      let dates = this.getDatesStrOfTheWeek(moment().weekday(-2).startOf('day'));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },

    onPreviousWeekClick() {
      let dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]).startOf('day'), -7));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },
    onNextWeekClick() {
      let dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]).startOf('day'), 7));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.search();
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaMissionPlanningAccommodationFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {};
      let dates = this.getDatesStrOfTheWeek(moment().weekday(0));
      this.filter.dateStart = this.dateToUtc(dates[0]);
      this.filter.dateEnd = this.dateToUtc(dates[6]);
      this.toggleFilterTitle(
        'GaMissionPlanningAccommodationFilter',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    getDayShort,
    getMonthShort,
  },
};
</script>

<template>
  <GaView>
    <template #action v-if="!this.$isMobile()">
      <span @click="getCurrentWeek()" class="btn-primary-gradient xs cursor-pointer">Aujourd'hui</span>
    </template>

    <template #content>
      <div class="flex justify-start items-center gap-2 mb-2" v-if="this.dates">
        <i
          class="ga-icon ga-chevron_left text-4xl text-gray cursor-pointer hover:text-dark"
          @click="onPreviousWeekClick"></i>
        <span class="font-normal text-xl text-gray"
          >Du <span class="capitalize text-dark font-medium">{{ periodStartDisplay }}</span> au
          <span class="capitalize text-dark font-medium">{{ periodEndDisplay }}</span></span
        >
        <i
          class="ga-icon ga-chevron_right text-4xl text-gray cursor-pointer hover:text-dark"
          @click="onNextWeekClick"></i>
      </div>
      <GaListView @search="searchQuery" searchable>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.search"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.search"
            label="Hôte"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAutocompleteFilter
            v-model="filter.accommodationId"
            :init-method="autocompleteAccommodationById"
            :label="$t('accommodation_title')"
            :search-label="autocompleteAccommodationQuery"
            :search-method="autocompleteAccommodation"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <div class="planning" ref="planning" v-if="this.dates">
            <AccommodationPlanningList v-model="this.planninglist" :dates="this.dates" />
          </div>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
