<script>
/* COMPOSANTS */

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

/* SERVICES */

export default {
  props: {
    litige: {
      required: true,
    },
  },
  components: {},
  mixins: [utilsMixin, permissionsMixin, litigeStatusConst],
  mounted() {},
  computed: {
    priority() {
      if (this.isResolutionValidated || this.isResolutionGagne) return 'priority-low';
      else if (this.isResolutionComplete) return 'priority-medium';
      else if (this.isStatusATraiter) return 'priority-high';
      else return 'priority-critical';
    },
    priorityText() {
      if (this.litige.resolution) return this.displayForLitigeStatusConst(this.litige.resolution);
      return this.displayForLitigeStatusConst(this.litige.status);
    },
    isResolutionComplete() {
      return this.litige.resolution === 'COMPLETE';
    },
    isResolutionValidated() {
      return this.litige.resolution === 'VALIDATED';
    },
    isResolutionRefused() {
      return this.litige.resolution === 'REFUSED';
    },
    isResolutionGagne() {
      return this.litige.resolution === 'GAGNE';
    },
    isStatusATraiter() {
      return this.litige.status === 'A_TRAITER';
    },
    isStatusEnCours() {
      return this.litige.status === 'EN_COURS';
    },
    isStatusResolu() {
      return this.litige.status === 'RESOLU';
    },
    statusATraiterText() {
      return 'Créé il y a ' + this.fromNow(this.litige.dateCreated);
    },
    statusEnCoursText() {
      let txt = 'Démarré il y a';
      if (this.isResolutionValidated) txt = 'Validé il y a';
      else if (this.isResolutionRefused) txt = 'Marqué comme incomplet il y a';
      return txt + ' ' + this.fromNow(this.litige.dateReview);
    },
    statusResoluText() {
      return 'Terminé il y a ' + this.fromNow(this.litige.dateDecision);
    },
  },
  methods: {},
};
</script>

<template>
  <!-- Composant dynamique pour désactiver le lien si l'utilisateur est un hôte -->
  <component
    :is="this.isOwner() ? 'div' : 'router-link'"
    :to="{name: 'litigeDetails', params: {litigeId: litige.id}}"
    class="nohover">
    <div
      class="flex flex-col gap-2.5 p-3 rounded-lg"
      :class="[{'bg-[#F0F1F2]': this.isStatusResolu, 'bg-white': !this.isStatusResolu}]"
      :key="litige.id">
      <div class="flex justify-between items-center">
        <span class="font-sf text-base text-dark font-590 priority-low px-4 py-2 rounded-3xl" :class="[priority]">
          {{ this.priorityText }}
        </span>
      </div>
      <div class="flex gap-4 items-center">
        <span class="text-primary text-lg font-medium"> {{ this.litige.localizer }} </span>
      </div>
      <div v-if="this.isGas()" class="flex gap-4 items-center">
        <span class="flex font-regular text-md text-dark capitalize font-bold">
          {{ this.litige.companyName }}
        </span>
      </div>
      <div class="flex gap-4 justify-between items-center">
        <span class="flex font-regular text-md text-dark capitalize" :class="{'font-bold': !this.isGas()}">{{
          this.litige.accommodationName
        }}</span>
        <span v-if="!this.isOwner()" class="flex font-regular text-md text-dark capitalize text-right">{{
          this.litige.ownerDisplay
        }}</span>
      </div>
      <span class="text-gray text-base font-regular">
        <span v-if="isStatusATraiter" :title="displayDateTime(this.litige.dateCreated)">
          {{ this.statusATraiterText }}
        </span>
        <span v-if="isStatusEnCours" :title="displayDateTime(this.litige.dateReview)">
          {{ this.statusEnCoursText }}
        </span>
        <span v-if="isStatusResolu" :title="displayDateTime(this.litige.dateDecision)">
          {{ this.statusResoluText }}
        </span>
      </span>
    </div>
  </component>
</template>
