import routes from './routes';
import {createRouter, createWebHistory} from 'vue-router';
import store from '@store';
import permissionService from '@services/permissionService';
import {useMessageStore} from '../store/messagesStore';
import {useAxios} from '../store/axiosStore';

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  //CANCEL ALL AXIOS REQUEST ON PREVIOUS PAGE
  const requestsStore = useAxios();
  if (requestsStore.getPage(routeFrom.path)) {
    for (let instance of requestsStore.getPage(routeFrom.path)) {
      instance.abort();
    }
    requestsStore.deletePage(routeFrom.path);
  }
  const messageStore = useMessageStore();
  messageStore.$reset();
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  // If auth is required and the user is logged in...
  if (store.getters['loggedIn']) {
    // Validate the local user token...
    store.dispatch('validate').then((validUser) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.
      if (!validUser) return redirectToLogin();
    });
  } else {
    return redirectToLogin();
  }
  const accessTo = routeTo.meta?.accessTo;
  const restrictedTo = routeTo.meta?.restrictedTo;
  const permissions = routeTo.meta?.permissions;

  if (!accessTo && !restrictedTo && !permissions) return next();
  if (accessTo && !permissionService.isOneOfThem(accessTo)) return redirectToUnauthorized();
  if (restrictedTo && permissionService.isOneOfThem(restrictedTo)) return redirectToUnauthorized();
  if (permissions && !permissionService.hasOneOfThem(permissions)) return redirectToUnauthorized();

  function redirectToLogin() {
    // Pass the original route to the login component
    return next({name: 'login', query: {redirectFrom: routeTo.fullPath}});
  }

  function redirectToUnauthorized() {
    // Pass the original route to the login component
    return next({name: 'unauthorized'});
  }

  return next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});
export default router;
