import axios from 'axios';
import store from '@store';
import router from '@router';
import {formatToUtc} from '../utils';
import qs from 'qs';
import {useAxios} from '@store/axiosStore';
import dateTransformer from '../utils/dateTransformer';

export default (secure = true, canCancel = true) => {
  let header = {};
  header['Accept'] = 'application/json';
  if (secure) {
    let user = store.state.currentUser;
    if (user && user.jwt) header['Authorization'] = 'token=' + user.jwt;
  }

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    isRefreshing = false;
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const instance = axios.create({
    baseURL: secure ? import.meta.env.VUE_APP_BASE_API_URL : import.meta.env.VUE_APP_PUBLIC_API_URL,
    withCredentials: false,
    headers: header,
  });
  instance.defaults.transformResponse = [dateTransformer];
  instance.interceptors.request.use((config) => {
    config.paramsSerializer = {
      serialize: (params) => {
        return qs.stringify(params, {
          serializeDate: function (d) {
            return formatToUtc(d);
          },
          arrayFormat: 'comma',
          commaRoundTrip: false,
        });
      },
    };

    if (canCancel) {
      const requestsStore = useAxios();
      if (requestsStore.get(config.url)) {
        const source = requestsStore.get(config.url);
        requestsStore.delete(config.url);
        source.abort();
      }

      const abortController = new AbortController();
      config.signal = abortController.signal;
      requestsStore.add(config.url, abortController);
      requestsStore.addPage(window.location.pathname, abortController);
    } else {
      const requestsStore = useAxios();
      const abortController = new AbortController();
      config.signal = abortController.signal;
      requestsStore.addPage(window.location.pathname, abortController);
    }
    return config;
  });
  instance.interceptors.response.use(
    function (response) {
      if (canCancel) {
        const requestsStore = useAxios();
        if (requestsStore.get(response.config.url)) {
          requestsStore.delete(response.config.url);
        }
      }
      return response;
    },
    function (error) {
      if (canCancel) {
        const requestsStore = useAxios();

        const originalRequest = error;
        if (requestsStore.get(originalRequest.url)) {
          requestsStore.delete(originalRequest.url);
        }
      }
      if (error.message === 'canceled') {
        return new Promise(() => {});
      } else if (error.response) {
        if (error.response.status === 403) {
          router.push({name: 'unauthorized'});
        } else if (error.response.status === 401 && secure) {
          if (error.response?.data?.error === 'Unauthorized') {
            let originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
              if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                  failedQueue.push({resolve, reject});
                })
                  .then(() => {
                    // originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    let user = store.state.currentUser;
                    if (user && user.jwt) {
                      originalRequest.headers['Authorization'] = 'token=' + user.jwt;
                    }
                    return axios(originalRequest);
                  })
                  .catch((error) => {
                    return Promise.reject(error);
                  });
              } else if (null != store?.state?.currentUser?.refreshToken) {
                originalRequest._retry = true;
                isRefreshing = true;
                return new Promise(function (resolve, reject) {
                  store
                    .dispatch('refresh')
                    .then(() => {
                      let user = store.state.currentUser;
                      if (user && user.jwt) {
                        originalRequest.headers['Authorization'] = 'token=' + user.jwt;
                      }
                      processQueue(null, user.jwt);
                      resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                      store.dispatch('logOutExpired');
                      reject(err);
                    })
                    .then(() => {
                      isRefreshing = false;
                    });
                });
              }
            }

            return Promise.reject(error);
          }
          store.dispatch('logOutExpired');
        } else if (error.response.status === 404 && !error.request.responseURL.includes('fic/')) {
          router.push({name: 'notFound'});
        } else if (error.response.status === 500) {
          router.push({name: 'unexpected'});
        } else if (error.response.status === 599) {
          return Promise.reject(error);
        }
      }
      if (
        error.request &&
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.size > 0 &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') != -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        }).then((err) => {
          // here your response comes
          // console.log(err.response.data);
        });
      }
      return Promise.reject(error);
    },
  );
  return instance;
};
