import {goBackTo} from '@utils';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: 'CRM',
          id: 'crm',
          active: true,
        },
        {
          name: 'Prospects',
          id: 'prospect',
        },
      ],
    };
  },
  methods: {},
};
