<script>
import AkReservationTooltip from './AkReservationTooltip';
import moment from 'moment';
import {Icon} from '@iconify/vue/dist/iconify';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  components: {},
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    dateRef: {
      type: moment,
      required: false,
      default: undefined,
    },
  },
  mixins: [permissionsMixin, sidebarMixin, messageMixin],
  data() {
    return {
      months: [],
      weekDays: [],
      group: [],
      reservationTooltip: undefined,
    };
  },
  emits: [
    'hide-loader',
    'tooltip',
    'next',
    'prev',
    'needReload',
    'edit-reservation',
    'add-reservation',
    'delete-reservation',
  ],
  updated() {
    this.$emit('hide-loader');
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    year() {
      return this.dateRef.year();
    },
  },
  created() {
    this.weekDays = moment.weekdaysMin(); // Get weekdays in the current locale
    this.weekDays.push(this.weekDays.shift()); // Move Sunday to the end
    this.generateYear();
    this.$emit('hide-loader');
  },
  watch: {
    year: function (newVal, oldVal) {
      if (newVal !== oldVal) this.generateYear();
    },
    currentValue: function (newVal, oldVal) {
      this.generateYear();
    },
  },
  methods: {
    addReservation(data) {
      this.$emit('add-reservation', data);
    },
    editReservation(data) {
      this.$emit('edit-reservation', data);
    },
    deleteReservation(data) {
      this.$emit('delete-reservation', data);
    },

    next() {
      this.$emit('next');
    },

    prev() {
      this.$emit('prev');
    },

    hasFirstSlot(reservations, month, day) {
      var d = moment({year: this.year, month: month.number, day: day.number});
      if (reservations && reservations.length === 1) {
        let startDate = reservations[0].dateStart;
        if (moment(startDate).startOf('date').isSame(d.startOf('date'))) return true;
      }
      return false;
    },

    hasLastSlot(reservations, month, day) {
      var d = moment({year: this.year, month: month.number, day: day.number});
      if (reservations && reservations.length === 1) {
        let startDate = reservations[0].dateEnd;
        if (moment(startDate).startOf('date').isSame(d.startOf('date'))) return true;
      }
      return false;
    },

    canDisplaySidebar(event, month, day) {
      if (!this.group) return false;
      if (!this.group[this.year]) return false;
      if (!this.group[this.year][month]) return false;
      if (!this.group[this.year][month][day]) return false;
      return true;
    },

    displaySidebar(event, month, day) {
      if (this.canDisplaySidebar(event, month, day)) {
        let list = this.group[this.year][month][day];
        let reservation = list[list.length - 1];
        this.displaySidebarForReservation(event, reservation);
        return;
      }
      this.addReservation([this.year, month, day]);
    },

    displaySidebarForReservation(event, reservation) {
      let data = reservation;
      data.header = data.localizer;
      this.toggleSidebar(
        'GaReservationSidebar',
        'Détails de la réservation',
        data.id,
        this.deleteReservation,
        this.editReservation,
      );
    },

    typeCss(r) {
      if (r === undefined) return '';
      if (this.isOwner()) {
        if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'booking-owner';
        if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'booking-blockade';
        if (r.dateCrg) return 'booking-clotured';
        if (r.statusSmily === 'Booked') return 'booking-booked';
        return '';
      }
      if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'booking-owner';
      if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'booking-blockade';
      if (r.dateCrg) return 'booking-clotured';
      if (r.statusSmily === 'Booked') return 'booking-booked';
      if (r.statusSmily === 'Tentative') return 'booking-tentative';
      return '';
    },

    groupReservation() {
      this.group = {};
      if (this.currentValue.reservationList) {
        this.currentValue.reservationList.forEach((objet) => {
          const currentDay = objet.dateStart.clone().startOf('date'); // Commence par la date de début

          while (currentDay.isSameOrBefore(objet.dateEnd, 'day')) {
            const y = currentDay.year();
            const m = currentDay.month();
            const d = currentDay.date();

            if (!this.group[y]) {
              this.group[y] = [];
            }
            if (!this.group[y][m]) {
              this.group[y][m] = [];
            }

            if (!this.group[y][m][d]) {
              this.group[y][m][d] = [];
            }
            this.group[y][m][d].push(objet);
            this.group[y][m][d].sort((a, b) => (a.dateStart.isBefore(b.dateStart) ? -1 : 1));

            currentDay.add(1, 'day'); // Passe au jour suivant
          }
        });
      }
      return this.group;
    },

    generateYear() {
      this.months = [];
      let reservationByDay = this.groupReservation();
      for (let i = 0; i < 12; i++) {
        const month = moment({year: this.year, month: i, day: 1});
        const monthDays = month.daysInMonth();
        const firstDay = month.isoWeekday();

        this.months.push({
          name: month.format('MMMM'),
          number: i,
          days: monthDays,
          weeks: this.generateWeeksArray(this.year, i, monthDays, firstDay, reservationByDay),
        });
      }
    },

    generateWeeksArray(year, month, monthDays, firstDay, reservationByDay) {
      const weeks = [];
      let week = Array(firstDay - 1).fill({number: 0}); // Fill with placeholders for previous month's days
      let resa = undefined;
      for (let day = 1; day <= monthDays; day++) {
        if (reservationByDay[year]) {
          if (reservationByDay[year][month]) {
            resa = reservationByDay[year][month][day];
          }
        }
        week.push({number: day, reservations: resa});
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }
      // Add placeholders for next month's days
      if (week.length > 0) {
        week = week.concat(Array(7 - week.length).fill({number: 0}));
        weeks.push(week);
      }
      return weeks;
    },

    isActive(reservations) {
      if (!this.isPartenaire() && !this.isHousekeeper()) return true;
      if (reservations === undefined) {
        return false;
      }
      return reservations.length > 0;
    },
  },
};
</script>
<template>
  <div class="flex gap-4 flex-wrap mb-2 md:mb-0">
    <div class="flex gap-2 items-center">
      <i class="ga-icon ga-hand_key text-dark text-2xl"></i
      ><span class="text-dark text-base font-normal">Arrivée physique</span>
    </div>
    <div class="flex gap-2 items-center">
      <i class="ga-icon ga-shirt_cleaning text-dark text-2xl"></i
      ><span class="text-dark text-base font-normal">Linge inclus</span>
    </div>
    <div class="flex gap-2 items-center" v-if="!this.isOwner()">
      <i class="w-[12px] h-[12px] rounded-full booking-booked"></i
      ><span class="text-dark text-base font-normal">Confirmée</span>
    </div>
    <div class="flex gap-2 items-center" v-if="this.isOwner()">
      <i class="w-[12px] h-[12px] rounded-full booking-booked"></i
      ><span class="text-dark text-base font-normal">Occupée</span>
    </div>
    <div class="flex gap-2 items-center">
      <i class="w-[12px] h-[12px] rounded-full booking-clotured"></i
      ><span class="text-dark text-base font-normal">Clôturée</span>
    </div>
    <div class="flex gap-2 items-center">
      <i class="w-[12px] h-[12px] rounded-full booking-blockade"></i
      ><span class="text-dark text-base font-normal">Indisponible</span>
    </div>
    <div class="flex gap-2 items-center">
      <i class="w-[12px] h-[12px] rounded-full booking-owner"></i
      ><span class="text-dark text-base font-normal">Hôte</span>
    </div>
    <div class="flex gap-2 items-center">
      <i class="w-[12px] h-[12px] rounded-full booking-tentative"></i
      ><span class="text-dark text-base font-normal">Demande d'informations</span>
    </div>
  </div>
  <div class="planning-yearly">
    <div class="flex items-center justify-center gap-3 mb-2">
      <i class="ga-icon ga-chevron_left text-4xl text-gray hover:text-primary hover:cursor-pointer" @click="prev()" />
      <div class="text-xl capitalize text-dark font-medium">
        {{ this.year }}
      </div>
      <i class="ga-icon ga-chevron_right text-4xl text-gray hover:text-primary hover:cursor-pointer" @click="next()" />
    </div>
    <div class="flex flex-col md:grid md:grid-cols-3 gap-4">
      <div v-for="(month, index) in months" :key="index">
        <span class="font-medium text-dark text-center text-md flex justify-center uppercase">{{ month.name }}</span>
        <table style="table-layout: fixed" class="mt-1">
          <thead>
            <tr>
              <th v-for="day in weekDays" class="text-gray uppercase font-normal text-base bg-lightGray" :key="day">
                {{ day }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, weekIndex) in month.weeks" :key="weekIndex">
              <td
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
                :class="[
                  this.isActive(day.reservations) ? 'pointer' : '',
                  dayIndex >= 5 ? 'wk' : '',
                  this.hasFirstSlot(day.reservations, month, day) ? 'start' : '',
                  this.hasLastSlot(day.reservations, month, day) ? 'last' : '',
                  day.reservations && day.reservations.length === 2 ? 'double' : '',
                ]"
                @click="displaySidebar($event, month.number, day.number)"
                class="text-slate-400 text-md">
                <span v-for="(r, resaIndex) in day.reservations" :key="resaIndex" :class="[typeCss(r)]" />
                <span :class="[day.reservations ? 'text-white' : '']" class="relative" style="z-index: 11">{{
                  day.number > 0 ? day.number : ''
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

td {
  position: relative;
}

.wk {
  background: #f8f9fa;
}
</style>
