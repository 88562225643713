<script>
import moment from 'moment/moment';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  mixins: [blockadeReasonsConst, permissionsMixin, reservationTypesConst, reservationSiteConst, sidebarMixin],
  emits: ['edit-reservation', 'delete-reservation'],
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    firstDate: {
      required: true,
    },
    lastDate: {
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openSidebar() {
      let data = this.reservation;
      data.header = data.localizer;
      this.toggleSidebar(
        'GaReservationSidebar',
        'Détails de la réservation',
        data.id,
        this.deleteReservation,
        this.editReservation,
      );
    },
    editReservation() {
      this.$emit('edit-reservation', this.reservation);
    },
    deleteReservation(data) {
      this.$emit('delete-reservation', data);
    },
  },
  computed: {
    checkinDate() {
      if (this.reservation.dateCheckin) {
        return moment(this.reservation.dateCheckin).startOf('day');
      }
      return moment(this.reservation.dateStart).startOf('day');
    },
    checkoutDate() {
      if (this.reservation.dateCheckout) {
        return moment(this.reservation.dateCheckout).startOf('day');
      }
      return moment(this.reservation.dateEnd).startOf('day');
    },
    airbnb() {
      if (this.reservation === undefined) return '';
      return this.reservation.reservationSite === 'Airbnb.com';
    },
    booking() {
      if (this.reservation === undefined) return '';
      return this.reservation.reservationSite === 'Booking.com';
    },
    blockade() {
      if (this.reservation === undefined) return '';
      return this.reservation.type === 'BLOCKADE';
    },
    owner() {
      if (this.reservation === undefined) return '';
      return this.reservation.type === 'OWNER';
    },
    hasUpsell() {
      return !!(this.reservation.upsellList && this.reservation.upsellList.length > 0);
    },
    typeCss() {
      console.log(this.reservation);
      if (this.isOwner()) {
        if (this.reservation.statusSmily === 'Unavailable' && this.reservation.type === 'OWNER') return 'booking-owner';
        if (this.reservation.statusSmily === 'Unavailable' && this.reservation.type === 'BLOCKADE')
          return 'booking-blockade';
        if (this.reservation.dateCrg) return 'booking-clotured';
        if (this.reservation.statusSmily === 'Booked') return 'booking-booked';
        return '';
      }
      if (this.reservation.statusSmily === 'Unavailable' && this.reservation.type === 'OWNER') return 'booking-owner';
      if (this.reservation.statusSmily === 'Unavailable' && this.reservation.type === 'BLOCKADE')
        return 'booking-blockade';
      if (this.reservation.dateCrg) return 'booking-clotured';
      if (this.reservation.statusSmily === 'Booked') return 'booking-booked';
      if (this.reservation.statusSmily === 'Tentative') return 'booking-tentative';
      return '';
    },
    roundCss() {
      if (this.firstDate > this.checkinDate && this.lastDate < this.checkoutDate) return '';
      if (this.firstDate <= this.checkinDate && this.lastDate >= this.checkoutDate) return 'rounded-full';
      if (this.firstDate >= this.checkinDate && this.lastDate >= this.checkoutDate) return 'rounded-r-full';
      if (this.lastDate >= this.checkinDate && this.lastDate <= this.checkoutDate) return 'rounded-l-full';

      return '';
    },
    cssId(r, slot, idxS) {
      if (r === undefined) return '';
      if (this.isFirstSlot(r, slot, idxS)) return r.id.toString();
      if (this.isFirst(r, slot)) return r.id.toString();
      return '';
    },
    title() {
      if (this.reservation === undefined) return '';
      if (this.reservation.type === 'GUEST') return this.reservation.title;
      if (this.reservation.type === 'BLOCKADE') return '';
      if (this.reservation.type === 'OWNER') return this.displayForReservationTypeConst(this.reservation.type);
      return '';
    },
    icon() {
      let site = this.reservation.reservationSite;
      let type = this.reservation.type;
      let value = this.iconReservation2(type, site);
      if (value === 'airbnb') return 'airbnb-white';
      return value;
    },
    bgIcon() {
      if (!this.reservation.reservationSite) return 'bg-white';
      if (this.reservation.reservationSite.includes('Airbnb')) return 'bg-airbnb';
      if (this.reservation.reservationSite.includes('Booking')) return 'bg-booking';
      if (this.reservation.reservationSite.includes('Vrbo')) return 'bg-vrbo';
      if (this.reservation.reservationSite.includes('homeaway') || this.reservation.reservationSite.includes('Abritel'))
        return 'bg-white';
      return 'bg-white';
    },
    colorIcon() {
      if (this.airbnb) return 'text-white';
      if (this.booking) return 'text-white';
      if (this.blockade) return 'text-red';
      if (this.owner) return 'text-dark';
      return 'text-white';
    },
    canDisplayTitle() {
      if (!this.reservation) return true;
      if (!this.reservation.dateCheckout) return true;
      if (!this.reservation.dateCheckin) return true;

      let duration = moment.duration(this.checkoutDate.diff(this.checkinDate));
      let days = duration.asDays();
      if (days >= 3) {
        let duration = moment.duration(this.checkoutDate.diff(this.firstDate));
        let duration2 = moment.duration(this.lastDate.diff(this.checkinDate));
        let days = duration.asDays();
        let days2 = duration2.asDays();
        if (days > 1 && days2 >= 1) return true;
        return false;
      }
      return false;
    },
    canDisplayIcon() {
      if (!this.reservation) return false;
      if (!this.reservation.dateCheckout) return false;
      if (!this.reservation.dateCheckin) return false;

      let duration = moment.duration(this.checkoutDate.diff(this.firstDate));
      let days = duration.asDays();
      if (days > 1) return true;
      return false;
    },
    period(data) {
      return (
        data.reservation[0].dateStart.format('DD/MM/YYYY') + ' - ' + data.reservation[0].dateEnd.format('DD/MM/YYYY')
      );
    },
    styleReservation() {
      if (this.checkinDate > this.lastDate) return 'display:none;';
      let colStart = undefined;
      let colEnd = undefined;
      if (this.checkinDate < this.firstDate) colStart = 1;
      else {
        let diff = this.checkinDate.diff(moment(this.firstDate), 'days');
        colStart = (diff + 1) * 2;
      }
      if (this.checkoutDate < this.firstDate) return 'display:none;';
      if (this.checkoutDate > this.lastDate) colEnd = -1;
      else {
        let diff = this.checkoutDate.diff(moment(this.firstDate), 'days');
        colEnd = (diff + 1) * 2;
      }
      return 'grid-column : ' + colStart + ' / ' + colEnd;
    },
  },
};
</script>

<template>
  <div
    @click="openSidebar"
    class="flex items-center h-[50px] px-2 gap-2 overflow-hidden cursor-pointer z-10"
    :class="[this.typeCss, this.roundCss]"
    :style="this.styleReservation">
    <span
      v-if="this.canDisplayIcon"
      class="w-[32px] h-[32px] flex items-center justify-center rounded-full"
      :class="[this.bgIcon, this.colorIcon]">
      <span
        class="logo-site bg-center bg-[length:18px_18px] w-[22px] h-[22px]"
        :class="icon"
        v-tooltip.bottom="reservation.reservationSite" />
    </span>

    <span
      v-if="this.canDisplayTitle"
      class="font-normal flex-1 text-white text-md overflow-hidden whitespace-nowrap text-ellipsis"
      >{{ this.title }}</span
    >
  </div>
</template>
