<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
    little: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    buildClass() {
      if (this.class) return this.class;
      return [
        {
          xs: this.little,
          'btn-primary-gradient': !this.secondary && !this.danger && !this.success,
          'btn-light-gray': this.secondary,
          'btn-danger': this.danger,
          'btn-success': this.success,
          disabled: this.disabled,
        },
      ];
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<template>
  <button :disabled="this.disabled" :class="buildClass" type="submit" @click="click()">
    {{ this.label }}
  </button>
</template>
