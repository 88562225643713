import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  owners(filter) {
    return Api()
      .get('/owners', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ownerCrm(filter) {
    return Api()
      .get('/owners/crm', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  exporOwnerCrm(filter) {
    return Api()
      .get('/owners/crm/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  ownersAutocomplete(filter) {
    return Api()
      .get('/owners/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ownersPagination(filter) {
    return Api()
      .get('/owners/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  count(filter) {
    return Api(true, false)
      .get('/owner/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  owner(id) {
    return Api()
      .get('/owner/' + id)
      .then((res) => this.formatRes(res.data));
  },

  ownerResume(id) {
    return Api()
      .get('/owner/' + id + '/resume')
      .then((res) => this.formatRes(res.data));
  },

  create(owner) {
    return Api()
      .post('/owner', owner)
      .then((res) => this.formatRes(res.data));
  },
  update(owner) {
    return Api()
      .put('/owner/' + owner.id, owner)
      .then((res) => this.formatRes(res.data));
  },
  enable(owner) {
    return Api()
      .get('/owner/enable/' + owner.id)
      .then((res) => this.formatRes(res.data));
  },
  disable(owner) {
    return Api()
      .get('/owner/disable/' + owner.id)
      .then((res) => this.formatRes(res.data));
  },
  markAsLost(owner) {
    return Api()
      .get('/owner/' + owner.id + '/lost')
      .then(() => owner);
  },
  delete(owner) {
    return Api()
      .delete('/owner/' + owner.id)
      .then(() => owner);
  },
  forceDelete(owner) {
    return Api()
      .delete('/owner/' + owner.id, {params: {force: true}})
      .then(() => owner);
  },
  access(owner) {
    return Api()
      .post('/owner/' + owner.id + '/access')
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
    return e;
  },
};
