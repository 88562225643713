import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  comptacrgsConsultation(filter) {
    return Api()
      .get('/comptacrgs/consultation', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptacrgsHistoric(filter) {
    return Api()
      .get('/comptacrgs/historic', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptacrgsOwner(f) {
    return Api()
      .get('/comptacrgs/owner', {params: removeEmptyParams(f)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptacrgsMandat(filter) {
    return Api()
      .get('/comptacrgs/mandat', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptacrg(id) {
    return Api()
      .get('/comptacrg/' + id)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },
  comptacrgClosed(id) {
    return Api()
      .get('/comptacrg/closed/' + id)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },

  generateOne(id, dateEnd) {
    return Api()
      .post('/comptacrg', {ids: [id], dateEnd: dateEnd})
      .then((res) => this.formatRes(res.data));
  },
  generateMany(ids, dateEnd) {
    return Api()
      .post('/comptacrg', {ids: ids, dateEnd: dateEnd})
      .then((res) => this.formatRes(res.data));
  },

  closeOne(id) {
    return Api()
      .post('/comptacrg/close', {ids: [id]})
      .then((res) => this.formatRes(res.data));
  },
  closeMany(ids) {
    return Api()
      .post('/comptacrg/close', {ids: ids})
      .then((res) => this.formatRes(res.data));
  },

  regenerateOne(id) {
    return Api()
      .post('/comptacrg/regenerate', {ids: [id]})
      .then((res) => this.formatRes(res.data));
  },
  regenerateMany(ids) {
    return Api()
      .post('/comptacrg/regenerate', {ids: ids})
      .then((res) => this.formatRes(res.data));
  },

  exportCrg(id) {
    return Api()
      .get('/comptacrg/' + id + '/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },

  exportCrgAnnuel(filter) {
    return Api()
      .get('/crg/annuel/export', {
        params: removeEmptyParams(filter),
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },

  formatRes(e) {
    return e;
  },
};
