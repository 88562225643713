<script>
import $ from 'jquery';
import moment from 'moment';
import {Icon} from '@iconify/vue';
import {useSidebarStore} from '@store/sidebarStore';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import GaHelpMessage from '@components/v2/general/GaHelpMessage';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationPlanningService from '@services/reservationPlanningService';
import reservationService from '@services/reservationService';

export default {
  components: {
    AkDialog,
    AkPictureAccommodation,
    GaHelpMessage,
    Icon,
  },
  mixins: [
    loaderMixin,
    permissionsMixin,
    reservationSiteConst,
    reservationTypesConst,
    upsellStatusConst,
    blockadeReasonsConst,
    utilsMixin,
    smilyStatusConst,
  ],
  emits: ['edit-reservation'],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      reservation: undefined,
    };
  },
  methods: {
    openDeleteDialog(event) {
      this.$refs.dialogConfirmDelete.show();
      if (event) event.stopPropagation();
    },
    cancelReservation() {
      reservationPlanningService
        .cancelReservationHote(this.reservation)
        .then((data) => {
          this.callback[0](true);
        })
        .catch(() => {
          this.callback[0](false);
        })
        .finally(() => {
          this.$refs.dialogConfirmDelete.hide();
          this.close();
        });
    },
    close() {
      useSidebarStore().$reset();
    },
    toggleElement(ref, event) {
      $(this.$refs[ref]).toggle();
      $(event.target).toggleClass('-rotate-90');
    },
  },
  mounted() {
    reservationService.reservationSidebar(this.currentValue).then((res) => {
      this.reservation = res;
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    canDisplayYagooLink() {
      return (
        this.reservation.boardingPass && moment(this.reservation.dateEnd).add(1, 'day').startOf('day').isAfter(moment())
      );
    },
    canDeleteReservation() {
      return (
        this.callback[0] &&
        this.reservation.statusSmily === 'Unavailable' &&
        this.reservation.type === 'OWNER' &&
        this.reservation.externalId != undefined &&
        moment(this.reservation.dateEnd).isAfter(moment()) &&
        moment(this.reservation.dateStart).isAfter(moment())
      );
    },
    isTypeGuest() {
      return this.reservation.type === 'GUEST' || this.reservation.type === 'GUEST_CANCEL';
    },
    isTypeOwner() {
      return this.reservation.type === 'OWNER' || this.reservation.type === 'OWNER_CANCEL';
    },
    period() {
      if (!this.currentValue) return '';
      let dateStart = this.currentValue.dateStart;
      let dateEnd = this.currentValue.dateEnd;

      let start = dateStart ? this.displayDate(dateStart) : '';
      let end = dateEnd ? this.displayDate(dateEnd) : '';
      return start + ' - ' + end;
    },
    status() {
      if (!this.reservation) return '';
      if (this.reservation.statusSmily === 'Unavailable') {
        if (this.reservation.type === 'OWNER') return 'Hôte';
        return 'Indisponible';
      }
      return this.displayForSmilyStatusConst(this.reservation.statusSmily);
    },
    dateCreated() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCreated;
      return date ? this.displayDate(date) : '-';
    },
    dateCheckin() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCheckin;
      return date ? this.displayDate(date) : '-';
    },
    dateCheckout() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCheckout;
      return date ? this.displayDate(date) : '-';
    },
    hourCheckin() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCheckin;
      return date ? this.displayTime(date) : '-';
    },
    hourCheckout() {
      if (!this.reservation) return '';
      let date = this.reservation.dateCheckout;
      return date ? this.displayTime(date) : '-';
    },
    isBookedOrCancelReservation() {
      return this.reservation.statusSmily !== 'Unavailable' && this.isTypeGuest;
    },
    isBookedReservation() {
      return this.reservation.statusSmily === 'Booked' && this.isTypeGuest;
    },
    isCancelReservationAccepted() {
      return this.reservation.statusSmily === 'Canceled' && this.reservation.statusRefund === 'ACCEPTED';
    },
    displayCommission() {
      if (this.isOwner()) {
        let val = this.reservation.commission ? this.reservation.commission : 0;
        if (this.isSiteVga) {
          val += this.reservation.feeService ? this.reservation.feeService : 0;
        }
        return this.formatCurrency(val, this.reservation.currency);
      } else {
        let display = this.formatCurrency(this.reservation.commission, this.reservation.currency);
        if (this.isSiteVga) {
          display += ' (' + this.formatCurrency(this.reservation.feeService, this.reservation.currency) + ')';
        }
        return display;
      }
    },
    iconResa() {
      if (!this.reservation.reservationSite) {
        if (this.reservation.type === 'OWNER') return 'owner';
        return 'blockade';
      }
      if (this.reservation.reservationSite.includes('Airbnb')) {
        return 'airbnb';
      }
      if (this.reservation.reservationSite.includes('Booking')) {
        return 'booking';
      }
      if (this.reservation.reservationSite.includes('Vrbo')) {
        return 'vrbo';
      }
      if (
        this.reservation.reservationSite.includes('homeaway') ||
        this.reservation.reservationSite.includes('Abritel')
      ) {
        return 'homeaway';
      }
      return 'guestadom';
    },

    hasUpsell() {
      return this.reservation.upsellList && this.reservation.upsellList.length > 0;
    },
    canUpdateReservation() {
      let oldHour = moment(this.reservation.dateCheckin).hour();
      let oldMin = moment(this.reservation.dateCheckin).minute();
      let checkIn = moment(this.reservation.dateStart).hour(oldHour).minute(oldMin);
      return (
        this.callback[1] &&
        this.reservation.statusSmily === 'Unavailable' &&
        this.reservation.type === 'OWNER' &&
        this.reservation.externalId != undefined &&
        moment(this.reservation.dateEnd).isAfter(moment()) &&
        checkIn.isAfter(moment())
      );
    },
  },
};
</script>

<template>
  <div>
    <div v-if="reservation" class="pr-2">
      <div>
        <div class="flex justify-between">
          <div>
            <div class="font-inter text-dark font-medium text-md">
              Réservation
              <router-link
                v-if="!this.isOwner"
                @click="close"
                class="font-inter text-dark font-medium text-md"
                :to="{
                  name: 'reservationPage',
                  params: {reservationId: reservation.id},
                }"
                >{{ reservation.localizer }}
              </router-link>
              <span v-else class="font-inter text-dark font-medium text-md">{{ reservation.localizer }}</span>
            </div>
            <div class="font-inter text-gray text-sm font-medium">le {{ dateCreated }}</div>
          </div>
          <a :href="reservation.boardingPass" target="_blank" v-if="this.canDisplayYagooLink"
            ><i class="ga-icon ga-external text-dark text-2xl"
          /></a>
        </div>

        <div class="font-inter text-lg font-medium w-full text-center mt-4">{{ status }}</div>
        <div class="bg-lightGray rounded-xl justify-center items-center flex flex-col pt-4 mt-4">
          <span class="logo-site" :class="iconResa" />

          <div class="pt-4 w-full flex">
            <AkPictureAccommodation
              :id="reservation.accommodationId"
              :name="reservation.accommodationName"
              :md5="reservation.accommodationMd5"
              :physical="reservation.physical"
              :laundry="reservation.laundry"
              @click="close"
              cover
              height="134" />
          </div>
        </div>
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('reservationData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Données réservation</span>
        </div>
        <div ref="reservationData" class="grid grid-cols-2 mt-4 gap-4">
          <div class="flex flex-col gap-1 col-span-2">
            <span class="font-inter font-semibold text-dark text-base">Nombre de nuits</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.nbNights }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Date de check-in</span>
            <span class="font-inter font-medium text-gray text-md">{{ dateCheckin }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Date de check-out</span>
            <span class="font-inter font-medium text-gray text-md">{{ dateCheckout }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Heure de check-in</span>
            <span class="font-inter font-medium text-gray text-md">{{ hourCheckin }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Heure de check-out</span>
            <span class="font-inter font-medium text-gray text-md">{{ hourCheckout }}</span>
          </div>
          <div class="flex flex-col gap-1 col-span-2" v-if="this.isTypeOwner && reservation.comments">
            <span class="font-inter font-semibold text-dark text-base">Commentaire hôte</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.comments }}</span>
          </div>
        </div>

        <div class="flex items-center gap-2 mt-3" v-if="isTypeGuest">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('clientData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Données voyageur</span>
        </div>
        <div ref="clientData" class="grid grid-cols-2 mt-4 gap-4" v-if="isTypeGuest">
          <div class="flex flex-col gap-1 col-span-2">
            <span class="font-inter font-semibold text-dark text-base">Client</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.guestDisplay }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Téléphone</span>
            <span class="font-inter font-medium text-gray text-md">{{
              reservation.guestPhone ? reservation.guestPhone : '-'
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">E-mail</span>
            <span class="font-inter font-medium text-gray text-md break-all whitespace-pre-wrap">{{
              reservation.guestEmail ? reservation.guestEmail : '-'
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Adultes</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.nbAdults }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">Enfants</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.nbChildren }}</span>
          </div>
          <div class="flex flex-col gap-1 col-span-2" v-if="!this.isOwner() && reservation.comments">
            <span class="font-inter font-semibold text-dark text-base">Commentaire</span>
            <span class="font-inter font-medium text-gray text-md">{{ reservation.comments }}</span>
          </div>
        </div>
        <div
          class="flex items-center gap-2 mt-3"
          v-if="this.reservation && this.isBookedOrCancelReservation && !this.isPartenaire()">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('montantData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Montants</span>
        </div>
        <div
          ref="montantData"
          class="grid grid-cols-2 mt-4 gap-4"
          v-if="this.reservation && this.isBookedOrCancelReservation && !this.isPartenaire()">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between">
              Montant total

              <GaHelpMessage title="Montant total" :message="this.$t('help_amount.montant_total')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.price, reservation.currency)
            }}</span>
          </div>
          <div></div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between">
              Commission de la plateforme
              <GaHelpMessage title="Commission de la plateforme" :message="this.$t('help_amount.commission')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{ displayCommission }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Taxe de séjour <GaHelpMessage title="Taxe de séjour" :message="this.$t('help_amount.tds')"
            /></span>
            <span class="font-inter font-medium text-gray text-md"
              >{{ formatCurrency(reservation.tdsAmount ? reservation.tdsAmount : 0, reservation.currency)
              }}<span class="font-inter font-medium text-gray text-md" v-if="reservation.tdsAmountForAirbnb">
                ({{
                  formatCurrency(
                    reservation.tdsAmountForAirbnb ? reservation.tdsAmountForAirbnb : 0,
                    reservation.currency,
                  )
                }})</span
              ></span
            >
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Frais de ménage
              <GaHelpMessage title="Frais de ménage" :message="this.$t('help_amount.cleaning')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.feeHousework, reservation.currency)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Caution déposée
              <GaHelpMessage title="Caution déposée" :message="this.$t('help_amount.caution')" />
            </span>
            <!--<span class="font-inter font-medium text-gray text-md">{{ reservation.cautionDone ? 'Oui' : 'Non' }}</span>-->
            <span class="font-inter font-medium text-gray text-md">---</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Montant des nuits
              <GaHelpMessage title="Montant des nuits" :message="this.$t('help_amount.night_price')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.nightsPrice, reservation.currency)
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Prix moyen des nuits
              <GaHelpMessage title="Prix moyen des nuits" :message="this.$t('help_amount.price_by_night')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.priceByNight, reservation.currency)
            }}</span>
          </div>
        </div>

        <div
          class="flex items-center gap-2 mt-3"
          v-if="
            this.reservation && (this.isBookedReservation || this.isCancelReservationAccepted) && !this.isPartenaire()
          ">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('revenuData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Revenus</span>
        </div>
        <div
          ref="revenuData"
          class="grid grid-cols-2 mt-4 gap-4"
          v-if="
            this.reservation && (this.isBookedReservation || this.isCancelReservationAccepted) && !this.isPartenaire()
          ">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Revenu hôte
              <GaHelpMessage title="Revenu hôte" :message="this.$t('help_amount.owner')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.priceOwner, reservation.currency)
            }}</span>
          </div>
          <div></div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Frais de gestion Guestadom
              <GaHelpMessage title="Frais de gestion Guestadom" :message="this.$t('help_amount.guestadom')" />
            </span>
            <span class="font-inter font-medium text-gray text-md" v-if="!this.isOwner()">{{
              formatCurrency(reservation.priceGas, reservation.currency)
            }}</span>
            <span class="font-inter font-medium text-gray text-md" v-else>{{
              formatCurrency(reservation.priceGav + reservation.priceGas, reservation.currency)
            }}</span>
          </div>
          <div class="flex flex-col gap-1" v-if="!this.isOwner()">
            <span class="font-inter font-semibold text-dark text-base flex gap-2 items-center justify-between"
              >Frais de gestion concierge partenaire
              <GaHelpMessage
                title="Frais de gestion concierge partenaire"
                :message="this.$t('help_amount.partenaire')" />
            </span>
            <span class="font-inter font-medium text-gray text-md">{{
              formatCurrency(reservation.priceGav, reservation.currency)
            }}</span>
          </div>
        </div>
        <div class="flex gap-2 justify-center mt-8" v-if="this.isGasGav() || this.isOwner()">
          <span
            v-if="canUpdateReservation"
            class="btn-primary-gradient xs cursor-pointer"
            @click="
              close();
              this.callback[1](this.reservation);
            ">
            Modifier cette réservation hôte
          </span>
          <span v-if="canDeleteReservation" class="btn-action bg-red xs" @click="openDeleteDialog">
            Supprimer cette réservation hôte
          </span>
        </div>
      </div>
      <!--<h5>Données réservation</h5>
      <p>
        <b>Logement :</b> <span class="poppins-bold text-uppercase">{{ currentValue.ownerDisplay }}</span> -
        {{ currentValue.accommodationName }}
        <Icon class="icon-tooltip" v-if="currentValue.physical" icon="ic:twotone-vpn-key" color="black" />
        <Icon class="icon-tooltip" v-if="currentValue.laundry" icon="ic:twotone-local-laundry-service" color="black" />
      </p>
      <p>
        <b>Date de création de réservation :</b>
        {{ dateCreated }}
      </p>
      <p>
        <b>Statut smily de la réservation :</b>
        {{ statusSmily }}
      </p>
      <p v-if="this.currentValue.type === 'GUEST' && this.currentValue.statusSmily === 'Booked' && canDisplayYagooLink">
        <b>Livret d'accueil :</b>
        <a :href="this.currentValue.boardingPass" v-if="this.currentValue.boardingPass" target="_blank">
          <Icon class="icon-book-link" icon="fluent:book-open-globe-24-regular" />
        </a>
      </p>


      <div v-if="this.hasUpsell && this.isGasGav()">
        <h5 class="mt-1">Extras</h5>

        <div v-for="u in this.currentValue.upsellList" :key="u.id">
          <div class="d-flex flex-column mb-1">
            <p>{{ u.name }} - {{ u.amount }}€</p>
            <p>
              {{ this.displayForUpsellStatusConst(u.status) }} -
              {{ this.displayForUpsellStatusEnsoConst(u.statusEnso) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canDeleteReservation" class="mt-2">
      <span class="btn btn-primary button pointer" @click="cancelReservation(currentValue)">
        Annuler la reservation
      </span>
    </div>
  </div>-->
    </div>
    <div v-else class="flex flex-col items-center gap-2 pt-4">
      <span class="spinner primary xl"></span>
      <span class="text-gray font-normal font-inter">Récuperation en cours...</span>
    </div>
  </div>
  <AkDialog
    ref="dialogConfirmDelete"
    :cancel-label="$t('no')"
    :title="$t('confirm_dialog')"
    :validate-label="$t('yes')"
    width="450px"
    @validate="this.cancelReservation">
    <div class="flex items-center gap-2">
      <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
      <span>{{ $t('unavailability.confirm_delete') }}</span>
    </div>
  </AkDialog>
</template>
