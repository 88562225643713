/* MIXINS POUR LES AVIS (REVIEWS) */
export default {
  methods: {
    colorRating(review) {
      if (!review.reservationSite) return 'text-gray';
      let site = review.reservationSite.toLowerCase();
      let rating = 0;
      if (site.includes('vrbo') || site.includes('booking') || site.includes('homeaway')) {
        rating = review.ratingTen;
        if (rating <= 6) {
          return 'text-red';
        } else if (rating <= 8) {
          return 'text-orangeStatus';
        }
        return 'text-green';
      } else if (site.includes('airbnb') || site.includes('guest-adom') || site.includes('guestadom')) {
        rating = review.rating;
        if (rating <= 4) {
          return 'text-red';
        } else if (rating <= 4.6) {
          return 'text-orangeStatus';
        }
        return 'text-green';
      }
      return 'text-gray';
    },
    colorRatingWithRating(s, rating) {
      if (!s) return 'text-gray';
      let site = s.toLowerCase();
      if (site.includes('vrbo') || site.includes('booking') || site.includes('homeaway') || site.includes('global')) {
        if (rating <= 6) {
          return 'text-red';
        } else if (rating <= 8) {
          return 'text-orangeStatus';
        }
        return 'text-green';
      } else if (site.includes('airbnb') || site.includes('guest-adom') || site.includes('guestadom')) {
        if (rating <= 4) {
          return 'text-red';
        } else if (rating <= 4.6) {
          return 'text-orangeStatus';
        }
        return 'text-green';
      }
      return 'text-gray';
    },

    colorCriteraRating(rating) {
      if (!rating) return 'text-gray opacity-50';
      if (rating <= 2) return 'text-red';
      if (rating >= 4) return 'text-green';
      return 'text-gray';
    },
  },
};
