<!-- DEPRECATED -->

<script>
/* COMPOSANTS */
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import companyService from '@services/companyService';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkAutocomplete,
    AkCalendar,
    MultiSelect,
  },
  mixins: [
    permissionsMixin,
    utilsMixin,
    sidebarMixin,
    accommodationAutocompleteMixin,
    ownerAutocompleteMixin,
    mandatAutocompleteMixin,
    reservationAutocompleteMixin,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
    };
  },
  mounted() {
    if (!this.isGav()) {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    }
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="isGas()"
      v-model="currentValue.companyIds"
      :options="companies"
      :placeholder="$t('employee.company')"
      class="form-control"
      filter
      :filter-placeholder="$t('filter')"
      option-label="name"
      option-value="id"
      @change="itemSelect" />
    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :init-method="autocompleteOwnerById"
      :placeholder="$t('owner.ownerDisplay')"
      option-label="display"
      option-value="id"
      :search-method="autocompleteOwner"
      @item-select="itemSelect" />
    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatIds"
      :init-method="autocompleteMandatById"
      :placeholder="$t('mandat.num')"
      itemDisplay="num"
      :search-method="autocompleteMandat"
      option-label="num"
      option-value="id"
      @item-select="itemSelect" />
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :init-method="autocompleteAccommodationById"
      :placeholder="$t('activity.accommodation')"
      option-label="display"
      option-value="id"
      :search-method="autocompleteAccommodation"
      @item-select="itemSelect" />
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :init-method="autocompleteReservationById"
      :placeholder="$t('reservation.number')"
      option-label="display"
      option-value="id"
      :search-method="autocompleteReservation"
      @item-select="itemSelect" />
    <AkCalendar
      v-model="currentValue.dateResiliation"
      :placeholder="$t('owner_cancel.date_cancel')"
      @value-change="itemSelect"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search()" @reset="reset()" />
  </form>
</template>
