<script>
/* COMPONENTS */
import GaTripleView from '@components/v2/layout/GaTripleView.vue';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation.vue';
import AkAvatar from '@components/general/AkAvatar.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';
import PrestationBadge from '@components/general/PrestationBadge.vue';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import partenaireService from '@services/partenaireService';
import itemLabelService from '@services/itemLabelService';
import accommodationService from '@services/accommodationService';

export default {
  components: {
    PrestationBadge,
    AkButtonActionValidation,
    AkAvatar,
    AkPictureAccommodation,
    GaTripleView,
  },
  mixins: [permissionsMixin, backMixin, messageMixin, civilitiesConst, utilsMixin, companyMixin, loaderMixin],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      partenaire: {
        companyId: null,
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: '',
          firstName: '',
          function: null,
        },
      },
      activeTab: undefined,
      editable: false,

      listFormeJuridique: [],
      accommodations: [],
      partenairePrestations: [],
    };
  },
  mounted() {
    this.initGoBack();
    this.refresh();
  },
  methods: {
    refresh() {
      this.showTotalLoaderGuestadom();
      partenaireService.partenaire(this.$route.params.partenaireId).then((result) => {
        this.partenaire = result;

        if (this.partenaire.partenairePrestations) {
          this.partenairePrestations = this.partenaire.partenairePrestations;
        }

        const promises = [];
        promises.push(
          accommodationService
            .accommodationThumbnails({companyId: this.partenaire.companyId, enabled: true})
            .then((accommodations) => {
              this.accommodations = accommodations.filter((accommodation) =>
                this.partenaire.accommodationIds.includes(accommodation.id),
              );
              this.accommodations.forEach((accommodation) => {
                accommodation.prestations = this.partenairePrestations.find(
                  (prestation) => prestation.accommodationId === accommodation.id,
                );
              });
            }),
          itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'}).then((data) => {
            this.listFormeJuridique = data;
          }),
        );

        Promise.all(promises).then(() => {
          this.hideLoader();
        });
      });
    },
    generateAccess() {
      partenaireService
        .access(this.partenaire)
        .then((data) => {
          this.partenaire = data;
          this.success(this.$t('partenaire.accessSent'));
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          if (this.$refs.btnAccess) this.$refs.btnAccess.init();
        });
    },
    enablePartenaire() {
      partenaireService
        .enable(this.partenaire)
        .then((data) => {
          this.partenaire = data;
          this.partenaire.userEnabled = true;
          this.success(this.$t('partenaire.enabled'));
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          if (this.$refs.btnEnable) this.$refs.btnEnable.init();
        });
    },
    disablePartenaire() {
      partenaireService
        .disable(this.partenaire)
        .then((data) => {
          this.partenaire = data;
          this.partenaire.userEnabled = false;
          this.success(this.$t('partenaire.disabled'));
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          if (this.$refs.btnDisable) this.$refs.btnDisable.init();
        });
    },
  },
  computed: {
    title() {
      return this.partenaire.contact.firstName + ' ' + this.partenaire.contact.lastName;
    },
  },
};
</script>

<template>
  <GaTripleView>
    <template #action>
      <AkButtonActionValidation
        v-if="!partenaire.userEnabled"
        ref="btnEnable"
        :label="$t('enable')"
        :messagePopup="$t('confirm_enable', [this.partenaire.display])"
        :titlePopup="$t('confirm_dialog')"
        success
        @click="enablePartenaire" />
      <AkButtonActionValidation
        v-if="partenaire.userEnabled"
        ref="btnDisable"
        :label="$t('disable')"
        :messagePopup="$t('confirm_disable', [this.partenaire.display])"
        :titlePopup="$t('confirm_dialog')"
        danger
        @click="disablePartenaire" />

      <AkButtonActionValidation
        v-if="!partenaire.userId && partenaire.userEnabled"
        ref="btnAccess"
        :label="$t('partenaire.createAccess')"
        :messagePopup="$t('partenaire.create_access_confirmation')"
        :titlePopup="$t('confirm_dialog')"
        secondary
        @click="generateAccess" />
      <AkButtonActionValidation
        v-if="partenaire.userId && partenaire.userEnabled"
        ref="btnAccess"
        :label="$t('partenaire.resendAccess')"
        :messagePopup="$t('partenaire.resend_access_confirmation')"
        :titlePopup="$t('confirm_dialog')"
        secondary
        @click="generateAccess" />

      <router-link
        v-if="partenaire.id"
        :to="{name: 'partenaireUpdate', params: {partenaireId: partenaire.id}}"
        class="btn-primary-gradient xs">
        {{ $t('partenaire.updateThis') }}
      </router-link>
    </template>
    <template #left>
      <div class="flex flex-col gap-4 md:gap-2">
        <div class="w-[160px] self-center">
          <AkAvatar
            v-if="partenaire.userId"
            ref="avatar"
            :first-name="partenaire.contact.firstName"
            :last-name="partenaire.contact.lastName"
            :user-id="partenaire.userId" />
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'contact', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Contact</span>
        </div>
        <div ref="contact">
          <div class="grid grid-cols-2 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.formeJuridique') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                listFormeJuridique.find((f) => f.id === partenaire.contact.formeJuridiqueId)?.label
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.raisonSociale') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.raisonSociale
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.companyName') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.companyName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Capital (€)</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.capital }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.villeRcs') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.villeRcs
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.siret') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.siret }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.civility') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayForCivilityConst(partenaire.contact.civility)
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.functionManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.function
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.lastNameManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.lastName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.firstNameManager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                partenaire.contact.firstName
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.email') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.email }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.phone }}</span>
            </div>
          </div>
          <div class="grid grid-cols-1 mt-4 gap-4 md:gap-2">
            <div v-if="isGas() || isAdmin()" class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">Agence</span>
              <span class="font-inter font-medium text-gray text-md break-words">{{
                displayForCompany(partenaire.companyId)
              }}</span>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'address', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Adresse</span>
        </div>
        <div ref="address">
          <div :class="partenaire.contact.address ? 'grid-cols-2' : 'grid-cols-1'" class="grid mt-4 gap-4 md:gap-2">
            <template v-if="partenaire.contact.address">
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.address1Manager') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  partenaire.contact.address
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.complement') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  partenaire.contact.complement
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.postalCode') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  partenaire.contact.postalCode
                }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.city') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.city }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.state') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{ partenaire.contact.state }}</span>
              </div>
              <div class="flex flex-col gap-1">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('address.country') }}</span>
                <span class="font-inter font-medium text-gray text-md break-words">{{
                  partenaire.contact.country
                }}</span>
              </div>
            </template>
            <div v-else class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.address1Manager') }}</span>
              <span class="font-inter font-medium text-gray text-md break-words">Adresse inconnue</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4 mt-4">
        <span class="title-border">Logements</span>
        <template v-if="accommodations?.length > 0 && partenaire.companyId">
          <div class="grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
            <div v-for="accommodation in accommodations" :key="accommodation.id">
              <AkPictureAccommodation
                :id="accommodation.id"
                :md5="accommodation.picture"
                :name="accommodation.name"
                class="rounded-[14px]"
                cover
                height="134" />
              <div class="flex flex-col gap-4 w-full mb-4 p-6 bg-white rounded-lg">
                <span class="font-inter font-semibold text-dark text-base">Prestations</span>
                <template v-if="accommodation?.prestations?.prestationTypes.length > 0">
                  <div
                    v-for="mission in accommodation.prestations.prestationTypes"
                    :key="mission"
                    class="flex justify-start items-center gap-4">
                    <PrestationBadge :type="mission" />
                  </div>
                </template>
                <span v-else>Aucune prestation à afficher</span>
              </div>
            </div>
          </div>
        </template>
        <span v-else>Aucun logement à afficher</span>
      </div>
    </template>
    <template #right></template>
  </GaTripleView>
</template>
