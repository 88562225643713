<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* COMPOSANTS */
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';
import AkCalendarPeriodFilter from '@components/v2/filter/AkCalendarPeriodFilter';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import AkDialog from '@components/general/AkDialog';
import AkNumberFilter from '@components/v2/filter/AkNumberFilter';
import GaListView from '@components/v2/layout/GaListView';
import GaSpanAmount from '@components/v2/general/GaSpanAmount';
import GaTripleView from '@components/v2/layout/GaTripleView';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import permissionConst, {PermissionMap} from '@mixins/const/permissionConst';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';

export default {
  components: {
    GaSpanAmount,
    AkCalendarPeriodFilter,
    AkAutocompleteFilter,
    AkAllFilter,
    Icon,
    AkDialog,
    GaListView,
    GaTripleView,
    AkDatatablePagination,
    AkNumberFilter,
  },
  mixins: [
    randomRef,
    messageMixin,
    loaderMixin,
    utilsMixin,
    permissionsMixin,
    permissionConst,
    sidebarMixin,
    reservationAutocompleteMixin,
  ],
  metaInfo() {
    return {
      title: 'accounting.transactions',
    };
  },
  data() {
    return {
      loading: false,
      list: [],
      listOperations: [],
      item: {},
      filter: {
        datePeriod: [moment().toDate(), moment().toDate()],
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      totalCredit: 0,
      totalDebit: 0,
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.TRANSACTION.MANAGE);
    },
  },
  methods: {
    // SEARCH

    clickSearch() {
      this.filter.page = 1;
      this.search();
    },

    search() {
      this.loading = true;
      this.listOperations = [];

      if (!this.filter.datePeriod) {
        this.filter.datePeriod = [moment().toDate(), moment().toDate()];
      }
      if (this.filter.datePeriod[0] > this.filter.datePeriod[1]) {
        this.filter.datePeriod.reverse();
      }
      let dateStart = this.filter.datePeriod[0];
      let dateEnd = this.filter.datePeriod[1];

      comptaOperationService
        .transactions({
          dateStart: dateStart,
          dateEnd: dateEnd,
          amount: this.filter.amount,
          reservationId: this.filter.reservationId,
          transactionTypes: this.filter.transactionTypes,
        })
        .then(this.loadOperations)
        .catch((e) => {
          if (e.response && e.response.status === 412) {
            this.addError(this.$t('account.no_exist'), true, true);
          }
        })
        .finally(() => (this.loading = false));
    },

    // LOAD OPERATIONS

    loadOperations(data) {
      this.list = data;
      for (const element of this.list) {
        element.operations[0].first = true;
        element.operations[0].locked = element.locked;
        this.listOperations.push(...element.operations);
      }
      let totalDebit = 0;
      let totalCredit = 0;
      for (let account of this.listOperations) {
        if (account.amount > 0) {
          totalCredit += account.amount;
        } else {
          totalDebit += account.amount;
        }
      }
      this.totalCredit = totalCredit;
      this.totalDebit = totalDebit;
    },

    // CAN

    canUpdateTransaction(data) {
      if (!this.hasManagePermission) return false;
      return !data.locked && !this.isOwnerChargeTransaction(data);
    },

    canDeleteTransaction(data) {
      if (!this.hasManagePermission) return false;
      return !data.locked;
    },

    isOwnerChargeTransaction(data) {
      return data.tableName === 'owner_charge';
    },

    // FORMAT

    formatAmount(num) {
      return Number.parseFloat(num).toFixed(2);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatAmount(-amount);
    },

    // DELETE

    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    deleteItem() {
      comptaOperationService
        .deleteTransaction(this.item.transactionId)
        .then((data) => {
          this.success(this.$t('operation.delete_transaction_ok'));
          this.search();
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          this.addError(content);
        });
    },

    // COMMENT

    displayComment(slotProp, event) {
      this.showComment(slotProp);
      event.stopPropagation();
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaTransactionsFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        datePeriod: [moment().toDate(), moment().toDate()],
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaTransactionsFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
};
</script>
<template>
  <GaTripleView :details="false">
    <template #content>
      <GaListView>
        <template #filter>
          <AkAutocompleteFilter
            v-model="filter.reservationId"
            :init-method="autocompleteReservationById"
            :label="$t('reservation.number')"
            :search-method="autocompleteReservation"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkNumberFilter v-model="filter.amount" :label="$t('accounting.amount')" mode="currency" @search="search" />
          <AkCalendarPeriodFilter v-model="filter.datePeriod" :label="$t('reservation.period')" @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :lazy="false"
            :loading="loading"
            :value="listOperations"
            rowGroupMode="subheader"
            groupRowsBy="transactionId">
            <template #empty>
              {{ $t('operation.empty') }}
            </template>
            <Column field="transactionId" header="Transaction" />
            <Column :header="$t('operation.date_transaction')">
              <template #body="slotProps">
                <span :title="slotProps.data.transactionId">{{ displayDate(dateToUtc(slotProps.data.date)) }}</span>
              </template>
            </Column>
            <Column :header="$t('operation.account')" field="accountNum" />
            <Column :header="$t('operation.company')" field="companyName" />
            <Column :header="$t('operation.label')">
              <template #body="slotProps">
                <div class="flex items-center justify-between gap-2">
                  <span class="flex-1">{{ slotProps.data.label }}</span>
                  <Icon
                    v-if="slotProps.data.comment"
                    class="cursor-pointer hover:text-primary"
                    icon="ph:info"
                    width="18px"
                    @click="displayComment(slotProps.data, $event)" />
                </div>
              </template>
            </Column>
            <Column :header="$t('operation.creator')" field="creatorName" />
            <Column :header="$t('operation.updater')" field="updaterName" />
            <Column :header="$t('operation.type')">
              <template #body="slotProps">
                <span>{{ $t('operation.' + slotProps.data.typeTransaction) }}</span>
              </template>
            </Column>
            <Column :header="$t('accounting.debit')" class="text-right">
              <template #body="slotProps">
                <span v-if="slotProps.data.debitDisplay !== ''">{{
                  slotProps.data.debitDisplay.replaceAll(' ', '&nbsp;')
                }}</span>
              </template>
            </Column>
            <Column :header="$t('accounting.credit')" class="text-right">
              <template #body="slotProps">
                <span v-if="slotProps.data.creditDisplay !== ''">{{
                  slotProps.data.creditDisplay.replaceAll(' ', '&nbsp;')
                }}</span>
              </template>
            </Column>
            <Column header-style=" width: 100px">
              <template #body="slotProps">
                <div class="flex justify-end items-center">
                  <template v-if="slotProps.data.first">
                    <router-link
                      v-if="canUpdateTransaction(slotProps.data)"
                      :to="{name: 'accountingTransactionUpdate', params: {transactionId: slotProps.data.transactionId}}"
                      class="btn btn-xs btn-inverse-secondary mr-1">
                      <i class="ga-icon ga-edit text-2xl text-primary" />
                    </router-link>

                    <span
                      v-if="canDeleteTransaction(slotProps.data)"
                      @click="openDeleteDialog(slotProps.data)"
                      class="cursor-pointer">
                      <i class="ga-icon ga-trash text-2xl text-red" />
                    </span>
                  </template>
                  <Icon
                    v-if="slotProps.data.dateClosed"
                    icon="material-symbols-light:lock-outline"
                    class="ml-1"
                    width="20" />
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
    <template #right>
      <div class="flex bg-detailsBg flex-col p-3 rounded-lg mt-2 gap-6" v-if="totalCredit && totalDebit">
        <div class="flex flex-row items-center gap-4">
          <i class="ga-icon ga-cash_in text-gray text-3xl" />
          <div class="flex flex-col gap-1">
            <span class="text-dark text-md font-medium">Montant total crédit</span>
            <GaSpanAmount green abs :value="totalCredit" />
          </div>
        </div>
        <div class="flex flex-row items-center gap-4">
          <i class="ga-icon ga-cash_out text-gray text-3xl" />
          <div class="flex flex-col gap-1">
            <span class="text-dark text-md font-medium">Montant total débit</span>
            <GaSpanAmount red abs :value="totalDebit" />
          </div>
        </div>
      </div>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="deleteItem()">
        <div class="flex items-center gap-2">
          <Icon icon="fluent:warning-20-regular" class="text-red" width="26" />
          <span>{{ $t('operation.delete_transaction') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaTripleView>
</template>

<style lang="scss" scoped>
:deep(.p-datatable) {
  .p-rowgroup-header {
    background: #dfe3e9 !important;

    td {
      padding: 1px 0 !important;
    }
  }

  .p-rowgroup-header:first-child {
    display: none;
  }
}
</style>
