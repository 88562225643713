<script>
/* COMPOSANTS */

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

/* SERVICES */

export default {
  props: {
    owner: {
      required: true,
    },
  },
  components: {},
  mixins: [utilsMixin, permissionsMixin, litigeStatusConst],
  mounted() {},
  computed: {
    priority() {
      if (this.owner.statusProspect === 'NOUVEAU') return 'priority-medium';
      else if (this.owner.statusProspect === 'ACTIF' && this.owner.onboardingOpen === true) return 'priority-high';
      else if (this.owner.statusProspect === 'PERDU') return 'priority-critical';
      else return 'priority-low';
    },
    priorityText() {
      if (this.owner.statusProspect === 'NOUVEAU') return 'Nouveau';
      else if (this.owner.statusProspect === 'ACTIF' && this.owner.onboardingOpen === true) return 'En cours';
      else if (this.owner.statusProspect === 'PERDU') return 'Perdu';
      else return 'Gagné';
    },
    contactDisplay() {
      return this.owner.contactList[0].display;
    },
    dateCreatedDisplay() {
      return 'Créé il y a ' + this.fromNow(this.owner.dateCreated);
    },
  },
  methods: {},
};
</script>

<template>
  <!-- Composant dynamique pour désactiver le lien si l'utilisateur est un hôte -->
  <component
    :is="this.isCommercial() && this.owner.status === 'CUSTOMER' ? 'div' : 'router-link'"
    :to="{name: this.owner.status === 'CUSTOMER' ? 'ownerPage' : 'prospectPage', params: {ownerId: this.owner.id}}"
    class="nohover">
    <div class="flex flex-col gap-2.5 p-3 rounded-lg bg-white" :key="this.owner.dateCreated">
      <div class="flex justify-between items-center">
        <span class="font-sf text-base text-dark font-590 priority-low px-4 py-2 rounded-3xl" :class="[priority]">
          {{ this.priorityText }}
        </span>
      </div>
      <div class="flex gap-4 items-center">
        <span class="text-primary text-lg font-medium"> {{ this.contactDisplay }} </span>
      </div>
      <div v-if="this.isGas() || this.isCommercial" class="flex gap-4 items-center">
        <span class="flex font-regular text-md text-dark capitalize font-bold">
          {{ this.owner.companyDisplay }}
        </span>
      </div>
      <div class="flex gap-4 justify-between items-center" v-if="this.owner.onboardingId">
        <span class="flex font-regular text-md text-dark capitalize">Onboarding n°{{ this.owner.onboardingId }}</span>
      </div>
      <span class="text-gray text-base font-regular">
        <span :title="displayDateTime(this.owner.dateCreated)">
          {{ this.dateCreatedDisplay }}
        </span>
      </span>
    </div>
  </component>
</template>
