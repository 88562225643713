<script>
/* COMPOSANTS */
import $ from 'jquery';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import reviewMixin from '@mixins/reviewMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';

/* SERVICES */
import reviewService from '@services/reviewService';

export default {
  components: {AkPictureAccommodation},
  mixins: [loaderMixin, permissionsMixin, randomRef, reservationSiteConst, sidebarMixin, reviewMixin, utilsMixin],
  setup() {
    return {};
  },
  data() {
    return {
      review: undefined,
    };
  },
  props: {
    modelValue: {required: true},
  },
  mounted() {
    reviewService.reviewSidebar(this.currentValue).then((data) => {
      this.review = data;
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    dateSubmittedAt() {
      if (!this.review) return '';
      return this.formatDateIfNeeded(this.review.submittedAt);
    },
    ratingTen() {
      if (!this.review) return '-';
      return this.formatAmount(this.review.ratingDisplay);
    },
  },
  methods: {
    toggleElement(ref, event) {
      $(this.$refs[ref]).toggle();
      $(event.target).toggleClass('-rotate-90');
    },
  },
};
</script>

<template>
  <div>
    <div v-if="this.review">
      <div class="font-inter text-dark font-medium text-md" v-if="!this.isOwner()">Avis n° {{ review.id }}</div>
      <div class="font-inter text-gray text-sm font-medium">le {{ this.dateSubmittedAt }}</div>

      <div class="bg-lightGray rounded-xl justify-center items-center flex flex-col pt-4 mt-4">
        <span
          class="logo-site"
          :class="iconReservation(this.review.reservationSite)"
          v-tooltip.bottom="review.reservationSite" />

        <div class="pt-4 w-full flex">
          <AkPictureAccommodation
            :id="review.accommodationId"
            :name="review.accommodationName"
            :md5="review.accommodationMd5"
            :physical="review.physical"
            :laundry="review.laundry"
            cover
            height="134" />
        </div>
      </div>

      <!-- DATA -->

      <section id="review-data">
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('reviewData', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">{{ $t('review.data') }}</span>
        </div>
        <div ref="reviewData" class="grid grid-cols-2 mt-4 gap-4">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('company.one') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ review.companyName }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('owner.ownerDisplay') }}</span>
            <span class="font-inter font-medium text-gray text-md" v-if="this.isPartenaire()">
              {{ review.ownerDisplay }}
            </span>
            <router-link
              v-else
              @click="hideSidebar()"
              class="text-primary hover:text-primary font-semibold"
              :to="{name: 'ownerPage', params: {ownerId: review.ownerId}}">
              {{ review.ownerDisplay }}
            </router-link>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('reservation.one') }}</span>
            <router-link
              @click="hideSidebar()"
              v-if="!this.isOwner()"
              class="text-primary hover:text-primary font-semibold"
              :to="{
                name: 'reservationPage',
                params: {reservationId: review.reservationId},
              }">
              {{ review.reservationNum }}
            </router-link>
            <span class="font-inter font-medium text-gray text-md" v-else>{{ review.reservationNum }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('review.name') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ review.name }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('review.rating_ten') }}</span>
            <span class="font-inter font-medium text-gray text-md" :class="this.colorRating(this.ratingTen)">{{
              this.ratingTen
            }}</span>
          </div>
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('review.review_title') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ review.title }}</span>
          </div>
        </div>
      </section>

      <!-- DETAILS -->

      <section id="review-details" class="mt-1">
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('reviewDetails', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">{{ $t('review.details') }}</span>
        </div>
        <div ref="reviewDetails" class="grid grid-cols-2 mt-4 gap-4">
          <div class="flex flex-col gap-1 col-span-2">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('review.public_comment') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ review.comment }}</span>
          </div>
          <div class="flex flex-col gap-1 col-span-2" v-if="review.privateComment">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('review.private_comment') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ review.privateComment }}</span>
          </div>
        </div>
      </section>

      <!-- CRITERIAS -->

      <section id="review-criterias" class="mt-1">
        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement('reviewCriterias', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">{{ $t('review.criterias') }}</span>
        </div>
        <div ref="reviewCriterias" class="grid grid-cols-1 mt-4 gap-4">
          <div class="flex flex-col gap-1">
            <div class="flex flex-1 justify-between">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.cleanliness') }}</span>
              <span
                class="font-inter font-medium text-gray text-md"
                :class="this.colorCriteraRating(review.criteriaCleanlinessRating)"
                ><i class="pi pi-star" /> {{ review.criteriaCleanlinessRating ?? '-' }} / 5</span
              >
            </div>
            <div class="flex flex-1" v-if="review.criteriaCleanlinessComment">
              <span class="font-inter font-medium text-gray text-md">{{ review.criteriaCleanlinessComment }}</span>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.checkin') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaCheckinRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaCheckinRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaCheckinComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaCheckinComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{
                  $t('review.criteria.communication')
                }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaCommunicationRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaCommunicationRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaCommunicationComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaCommunicationComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.location') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaLocationRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaLocationRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaLocationComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaLocationComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.facilities') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaFacilitiesRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaFacilitiesRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaFacilitiesComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaFacilitiesComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.comfort') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaComfortRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaComfortRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaComfortComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaComfortComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.staff') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaStaffRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaStaffRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaStaffComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaStaffComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{
                  $t('review.criteria.value_for_money')
                }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaValueForMoneyRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaValueForMoneyRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaStaffComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaValueForMoneyComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{ $t('review.criteria.accuracy') }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaAccuracyRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaAccuracyRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaAccuracyComment">
                <span class="font-inter font-medium text-gray text-md">{{ review.criteriaAccuracyComment }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{
                  $t('review.criteria.property_condition')
                }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaPropertyConditionRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaPropertyConditionRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaPropertyConditionComment">
                <span class="font-inter font-medium text-gray text-md">{{
                  review.criteriaPropertyConditionComment
                }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-1">
              <div class="flex flex-1 justify-between">
                <span class="font-inter font-semibold text-dark text-base">{{
                  $t('review.criteria.eco_friendliness')
                }}</span>
                <span
                  class="font-inter font-medium text-gray text-md"
                  :class="this.colorCriteraRating(review.criteriaEcoFriendlinessRating)"
                  ><i class="pi pi-star" /> {{ review.criteriaEcoFriendlinessRating ?? '-' }} / 5</span
                >
              </div>
              <div class="flex flex-1" v-if="review.criteriaEcoFriendlinessComment">
                <span class="font-inter font-medium text-gray text-md">{{
                  review.criteriaEcoFriendlinessComment
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-col items-center gap-2 pt-4">
      <span class="spinner primary xl"></span>
      <span class="text-gray font-normal font-inter">{{ $t('review.fetching') }}</span>
    </div>
  </div>
</template>
