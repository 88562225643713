export default {
  data() {
    return {
      maintenanceOwnerChoiceConst: [
        {label: this.$t('maintenance.accept'), value: 'ACCEPT'},
        {label: this.$t('maintenance.refus'), value: 'REFUS'},
      ],
      maintenanceManagedByConst: [
        {label: 'Guestadom', value: 'ACCEPT'},
        {label: this.$t('maintenance.owner'), value: 'REFUS'},
      ],
    };
  },
  methods: {
    displayForMaintenanceOwnerChoiceConst(value) {
      let result = this.maintenanceOwnerChoiceConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    displayForMaintenanceManagedByConst(value) {
      let result = this.maintenanceManagedByConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
