<script>
import {mapState} from 'pinia';
import {useMessageStore} from '@store/messagesStore';

/* COMPOSANTS */

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {},
  mixins: [messageMixin, loaderMixin],
  data() {
    return {
      tab: {},
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    firstTab: {type: String, default: undefined},
    largeTabs: {type: Boolean, default: false},
  },
  emits: ['tab'],
  mounted() {
    if (this.firstTab) {
      for (let t of this.tabsCanBeDisplay) {
        if (t.id === this.firstTab) {
          this.tab = t;
        }
      }
    } else {
      this.tab = this.tabsCanBeDisplay[0];
    }
  },

  methods: {
    display(tab) {
      this.tab = tab;
      this.$emit('tab', tab);
    },
    resetMessage() {
      useMessageStore().$reset();
    },
  },
  computed: {
    ...mapState(useMessageStore, ['list']),
    tabsCanBeDisplay() {
      return this.tabs.filter((t) => !t.hidden);
    },
  },
};
</script>

<template>
  <div class="flex flex-col gap-4 h-full">
    <perfect-scrollbar class="tabs border-b" :options="{suppressScrollY: true}">
      <ul class="flex tabs-ul" role="tablist" :class="{'h-12': largeTabs}">
        <template v-for="tab of tabsCanBeDisplay" :key="tab">
          <li
            v-if="!tab.hidden"
            :class="{active: this.tab.id === tab.id}"
            class="whitespace-pre tabs-item"
            @click="display(tab)">
            {{ tab.name }}
          </li>
        </template>
      </ul>
    </perfect-scrollbar>

    <perfect-scrollbar class="h-full" v-if="!this.$isMobile()">
      <div class="p-3 flex-1 bg-white rounded-lg">
        <slot :name="tab.id" v-if="tab" />
      </div>
    </perfect-scrollbar>
    <div class="p-3 flex-1 bg-white rounded-lg" v-else>
      <slot :name="tab.id" v-if="tab" />
    </div>
  </div>
</template>
