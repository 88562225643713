<script>
/* COMPOSANTS */
import AkAllFilter from '@components/v2/filter/AkAllFilter';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkPictureAccommodation from '@components/v2/general/AkPictureAccommodation';
import GaListView from '@components/v2/layout/GaListView';
import GaView from '@components/v2/layout/GaView';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import countryConst from '@mixins/const/countryConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import filterListMixin from '@mixins/filterListMixin';
import {useFilterStore} from '@store/filterStore';
import SplitButton from 'primevue/splitbutton';
import moment from 'moment';
import comptaCrgService from '@services/comptaCrgService';

export default {
  components: {
    AkAutocompleteFilter,
    AkAllFilter,
    AkDatatablePagination,
    AkListFilter,
    SplitButton,
    AkPictureAccommodation,
    GaListView,
    GaView,
  },
  mixins: [
    accommodationTypeConst,
    countryConst,
    companyMixin,
    loaderMixin,
    messageMixin,
    ownerAutocompleteMixin,
    permissionsMixin,
    randomRef,
    residenceTypeConst,
    sidebarMixin,
    filterListMixin,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: 'crg_annuel.list',
    };
  },

  data() {
    return {
      accommodations: [],
      totalItems: 0,
      loading: true,
      accommodationTypeFilter: {},
      filter: {
        enabled: null,
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      hasPrepublished: false,
    };
  },

  mounted() {
    let filter = useFilterStore().getFilter();
    if (filter) {
      this.filter = {...this.filter, ...filter};
    }
  },
  methods: {
    itemsForAccommodation(id) {
      var today = moment();
      var until = 2023;
      var yearNow = today.year();
      var dp = [];
      for (let i = until; i < yearNow; i++) {
        dp.push({label: 'Bilan annuel ' + i, command: () => this.download(id, i)});
      }

      return dp.reverse();
    },
    search() {
      this.loading = true;
      accommodationService
        .accommodationsPagination(this.filter)
        .then((data) => {
          this.accommodations = this.isOwner() ? data.data.filter((acc) => acc.status === 'PUBLISHED') : data.data;
          this.totalItems = data.totalItems;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    displayForPlace(data) {
      return data.city;
    },
    download(accommodationId, year) {
      this.showTotalLoaderWithAfter('Rapport en cours de création...');
      var rapport = {};
      rapport.accommodationId = accommodationId;
      rapport.year = year;
      comptaCrgService
        .exportCrgAnnuel(rapport)
        .then(() => {
          this.success('Rapport téléchargé');
        })
        .catch((e) => {
          if (e.response) {
            let message = this.msgForErr(this.$t, e);
            this.addError(message);
          } else {
            this.addError('Une erreur est survenue, merci de réessayer plus tard');
          }
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaAccommodationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        enabled: null,
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaAccommodationFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
  },
  computed: {
    btnLabel() {
      return 'Bilan annuel ' + moment().year();
    },
  },
};
</script>

<template>
  <GaView :title="$t('accommodation.list')">
    <template #action> </template>

    <template #content>
      <GaListView searchable @search="this.searchQuery">
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            :multi-selection="true"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :display-filter="true"
            @search="this.clickSearch"
            :label="$t('employee.company')"
            direction="right" />
          <AkAutocompleteFilter
            v-model="this.filter.ownerId"
            @search="this.clickSearch"
            :label="$t('role.owner')"
            item-value="id"
            item-display="display"
            direction="right"
            :init-method="this.autocompleteOwnerById"
            :search-method="this.autocompleteOwner"
            :search-object="this.autocompleteOwnerCustomer()"
            :search-label="this.autocompleteOwnerQuery" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination
            :loading="loading"
            :value="accommodations"
            :nb-items="this.totalItems"
            @search="this.search"
            :filter="this.filter">
            <template #empty>
              {{ $t('accommodation.empty') }}
            </template>

            <Column
              style="width: 60px; max-width: 60px; padding-left: 0px; padding-right: 0px"
              header="Photo"
              class="text-center"
              :sortable="false">
              <template #body="slotProps">
                <AkPictureAccommodation
                  :md5="slotProps.data.picture"
                  :id="slotProps.data.id"
                  :height="48"
                  :radius="8" />
              </template>
            </Column>

            <Column
              :header="$t('accommodation_title')"
              style="min-width: 200px; width: 400px"
              :sortable="true"
              field="name">
              <template #body="slotProps">
                <router-link :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.id}}">
                  {{ slotProps.data.name }}
                </router-link>
              </template>
            </Column>

            <Column
              style="min-width: 200px; width: 400px"
              v-if="!isOwner()"
              :header="$t('accommodation.ownerName')"
              :sortable="false"
              field="contact_first_name">
              <template #body="slotProps">
                <span v-if="this.isPartenaire()">{{ slotProps.data.ownerContactName }}</span>
                <router-link v-else :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                  {{ slotProps.data.ownerContactName }}
                </router-link>
              </template>
            </Column>

            <Column
              v-if="isGas()"
              style="width: 400px; min-width: 150px"
              :header="$t('company.company')"
              :sortable="true"
              field="j_company_name">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <img src="@refonte/img/guestadom.svg" class="z-10 h-6" alt="Guestadom logo" />
                  <span>{{ slotProps.data.companyName }}</span>
                </div>
              </template>
            </Column>

            <Column :header="$t('accommodation.localisation')" style="width: 300px" :sortable="true" field="city">
              <template #body="slotProps">
                {{ displayForPlace(slotProps.data) }}
              </template>
            </Column>

            <Column header="Description" style="width: 100px">
              <template #body="slotProps">
                <div class="flex gap-2 items-center">
                  <span v-if="slotProps.data.capacity" class="flex gap-1 items-center">
                    <i class="ga-icon ga-users text-2xl opacity-65"></i><span>{{ slotProps.data.capacity }}</span>
                  </span>
                  <span v-if="slotProps.data.nbRoom" class="flex gap-1 items-center">
                    <i class="ga-icon ga-bed text-2xl opacity-65"></i><span>{{ slotProps.data.nbRoom }}</span>
                  </span>
                  <span v-if="slotProps.data.nbBathroom" class="flex gap-1 items-center">
                    <i class="ga-icon ga-bath text-2xl opacity-65"></i><span>{{ slotProps.data.nbBathroom }}</span>
                  </span>
                </div>
              </template>
            </Column>
            <Column style="width: 200px" header="">
              <template #body="slotProps">
                <div class="flex justify-end">
                  <SplitButton
                    :label="btnLabel"
                    class="btn-light-gray xs"
                    @click="download(slotProps.data.id, 2025)"
                    :model="itemsForAccommodation(slotProps.data.id)" />
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
