<script>
import AkInputNumber from '@components/v2/input/AkInputNumber';

export default {
  components: {AkInputNumber},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: undefined,
    },
    fractionDigits: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  emits: ['update:modelValue', 'value-change'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  watch: {
    currentValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('value-change', newVal);
      }
    },
  },
};
</script>

<template>
  <AkInputNumber
    v-model="currentValue"
    :label="label"
    :placeholder="placeholder"
    :validator="validator"
    :className="className"
    :fractionDigits="fractionDigits"
    :submitted="submitted"
    :disabled="disabled"
    :required="required"
    :max="max"
    :min="min"
    :inline="inline"
    mode="currency" />
</template>
