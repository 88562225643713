<script>
/* SERVICES */

export default {
  props: {
    scale: {type: Number, default: 0.75},
    color: {type: String, default: 'var(--color-dark)'},
    message: {type: String, default: undefined},
    class: {type: String, default: ''},
  },
  data() {
    return {
      loading: undefined,
    };
  },
  mounted() {},
  methods: {
    hide() {
      this.loading = false;
    },
    load() {
      this.loading = true;
    },
    loadThen(promise, then) {
      this.load();
      promise.then(then).finally(this.hide);
    },
    loadThenCatch(promise, then, catcher) {
      this.load();
      promise.then(then).catch(catcher).finally(this.hide);
    },
    loadThenCatchFinally(promise, then, catcher, finalizer) {
      this.load();
      promise
        .then(then)
        .catch(catcher)
        .finally(() => {
          this.hide();
          finalizer();
        });
    },
  },
};
</script>

<template>
  <div v-if="loading === undefined" />
  <div v-else-if="loading" class="loader-div flex flex-col gap-2" :class="this.class">
    <vue-loaders :color="color" :scale="scale" class="flex items-center justify-center" name="ball-clip-rotate" />
    <h5 class="flex items-center justify-center text-dark font-normal" v-if="message">
      {{ message }}
    </h5>
  </div>
  <slot v-else name="content" />
</template>
<style>
.loader-div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
