import axios from 'axios';
import store from '@store';
import {setupCache} from 'axios-cache-interceptor/dev';

export default () => {
  let header = {};
  header['Accept'] = 'application/json';
  let user = store.state.currentUser;
  if (user && user.jwt) header['Authorization'] = 'token=' + user.jwt;

  const instance = axios.create({
    baseURL: import.meta.env.VUE_APP_BASE_API_URL,
    withCredentials: false,
    headers: header,
  });
  return setupCache(instance, {});
};
