<script>
/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import ContactRoleItem from '@views/mescontacts/comp/ContactRoleItem';
import ContactContactItem from '@views/mescontacts/comp/ContactContactItem';
import AkDropdown from '@components/input/AkDropdown';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import accommodationRoleService from '@services/accommodationRoleService';
import accommodationContactService from '@services/accommodationContactService';
import accommodationService from '@services/accommodationService';

export default {
  components: {
    AkDropdown,
    ContactContactItem,
    ContactRoleItem,
    GaView,
  },
  mixins: [randomRef, utilsMixin],
  metaInfo() {
    return {
      title: 'owner.mes_contacts',
    };
  },
  data() {
    return {
      roles: [],
      contacts: [],
      accommodations: [],
      search: {},
    };
  },
  mounted() {
    this.getRef().showLoader(this.$refs.form);

    let p1 = accommodationRoleService.mesContacts(this.search);
    p1.then((data) => {
      this.roles = data;
    });

    let p2 = accommodationContactService.mesContacts(this.search);
    p2.then((data) => {
      this.contacts = data;
    });

    let p3 = accommodationService.accommodations();
    p3.then((data) => {
      this.accommodations = data;
    });

    Promise.all([p1, p2, p3]).then(this.getRef().hideLoader());
  },
  methods: {
    filter() {
      this.getRef().showLoader(this.$refs.form);

      let p1 = accommodationRoleService.mesContacts(this.search);
      p1.then((data) => {
        this.roles = data;
      });

      let p2 = accommodationContactService.mesContacts(this.search);
      p2.then((data) => {
        this.contacts = data;
      });

      Promise.all([p1, p2]).then(this.getRef().hideLoader());
    },
  },
  computed: {
    accommodationsForSearch() {
      let list = [{id: null, display: 'Tous'}];
      return this.appendLists(list, this.accommodations);
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('owner.mes_contacts')">
    <template #search>
      <form class="form-inline search-form" @submit.prevent="filter">
        <AkDropdown
          v-model="search.accommodationId"
          :options="accommodationsForSearch"
          :placeholder="$t('accommodation.list')"
          class-name=""
          input-class="full-width-i"
          option-label="display"
          option-value="id"
          label="" />
        <button class="btn btn-primary btn-search" type="submit">Rechercher</button>
      </form>
    </template>
    <template #content>
      <span class="poppins-bold fs-20 color-secondary"> Ma conciergerie Guestadom </span>
      <div class="row mt-1 mb-3">
        <div v-for="(role, idx) in roles" :key="role.id" class="col-md-4">
          <ContactRoleItem v-model="roles[idx]" />
        </div>
      </div>

      <span class="poppins-bold fs-20 color-secondary"> Mes contacts utiles </span>
      <div class="row mt-1">
        <div v-for="(contact, idx) in contacts" :key="contact.id" class="col-md-4 mt-1">
          <ContactContactItem v-model="contacts[idx]" />
        </div>
      </div>
    </template>
  </GaView>
</template>
