<script>
/* COMPOSANTS */

/* MIXINS */
import backMixin from '@mixins/backMixin';
import {useActiveMeta} from 'vue-meta';

export default {
  mixins: [backMixin],
  props: {
    details: {type: Boolean, required: false, default: true},
    scrollable: {type: Boolean, required: false, default: false},
    justListView: {type: Boolean, required: false, default: false},
    titleCenter: {type: Boolean, required: false, default: false},
    showTitle: {type: Boolean, required: false, default: true},
  },
  computed: {
    hasLeftSlot() {
      return !!this.$slots.left;
    },
    hasRightSlot() {
      return !!this.$slots.right;
    },
    maxWidth() {
      let width = 5;
      if (this.hasLeftSlot) width = width - 1;
      if (this.hasRightSlot) width = width - 1;
      return width;
    },
    title() {
      return this.$i18n.t(useActiveMeta().title);
    },
  },
};
</script>

<template>
  <div>
    <div class="flex md:flex-row flex-col justify-between items-center mb-3">
      <div
        v-if="!titleCenter && showTitle"
        class="hidden md:flex items-center justify-items-start w-full md:flex-1 md:w-auto gap-2 min-h-[40px]">
        <a v-if="hasBack" @click="goBack" class="hover:cursor-pointer">
          <i class="ga-icon ga-chevron_left text-2xl font-bold text-primary" />
        </a>
        <span class="font-inter text-dark font-bold text-xl">{{ title }}</span>
      </div>
      <div class="flex gap-2 items-center justify-end w-full md:w-auto flex-wrap">
        <slot name="action" />
      </div>
    </div>
    <div class="flex gap-4 md:flex-row flex-col">
      <div class="p-3 md:w-1/5 w-full" v-if="hasLeftSlot">
        <perfect-scrollbar class="md:h-content-scroll h-full">
          <slot name="left" />
        </perfect-scrollbar>
      </div>
      <div class="flex-1" :class="'md:w-' + this.maxWidth + '/5'">
        <div
          v-if="titleCenter && showTitle"
          class="hidden md:flex items-center justify-items-start w-full md:flex-1 md:w-auto gap-2 min-h-[40px]">
          <a v-if="hasBack" @click="goBack" class="hover:cursor-pointer">
            <i class="ga-icon ga-chevron_left text-2xl font-bold text-primary" />
          </a>
          <span class="font-inter text-dark font-bold text-xl">{{ title }}</span>
        </div>
        <div
          v-if="!justListView && !this.$isMobile()"
          class="p-3 border-solid border-[#F1F2F5] rounded-lg"
          :class="[{'layout-scrollable': this.scrollable, 'bg-detailsBg': this.details, border: !this.details}]">
          <perfect-scrollbar class="md:h-content-scroll h-full" :options="{suppressScrollX: true}">
            <slot name="content" />
          </perfect-scrollbar>
        </div>
        <div v-else class="p-3 border-solid border-[#F1F2F5] rounded-lg bg-detailsBg">
          <slot name="content" />
        </div>
      </div>
      <div class="md:p-3 md:w-1/5 w-full" v-if="hasRightSlot">
        <perfect-scrollbar class="md:h-content-scroll h-full">
          <slot name="right" />
        </perfect-scrollbar>
      </div>
    </div>
    <slot name="empty" />
    <slot name="extra" />
  </div>
</template>
